:root {
  --action-max-size: 3rem;
  --header-base: 60px;
  --header-lg: 80px;
  --mm-color-background: #fff;
  --mm-ocd-width: 100vw;
  --mm-ocd-min-width: 100%;
  --mm-ocd-max-width: 100%;
  --topbar-height: 40px;
}

#Header {
  @apply sticky px-5 bg-white border-b border-gray-200 top-0;

  z-index: 55;
  min-height: var(--header-base);
  max-height: var(--header-base);
  transform: translateY(0);
  transition: transform 250ms ease-out;

  @screen lg {
    min-height: var(--header-lg);
    max-height: var(--header-lg);
  }

  @screen lg {
    padding-right: 0;
    padding-left: 1.875rem;
  }

  &.notSticky {
    transform: translateY(-100px);
  }

  body.had-topbar & {
    min-height: calc(var(--header-base) + var(--topbar-height));
    max-height: calc(var(--header-base) + var(--topbar-height));
    padding-top: var(--topbar-height);

    @screen lg {
      min-height: calc(var(--header-lg) + var(--topbar-height));
      max-height: calc(var(--header-lg) + var(--topbar-height));
    }

    .subNav {
      @screen lg {
        top: calc(var(--header-lg) + var(--topbar-height));
      }
    }
  }
}

.Header {
  &-top {
    @apply flex justify-between ;


    min-height: var(--header-base);
    max-height: var(--header-base);

    @screen lg {
      min-height: var(--header-lg);
      max-height: var(--header-lg);
    }
  }

  &-hamburger {
    @apply block lg:hidden relative;
  }

  &-toggle {
    position: absolute;
    top: 24px;
    left: 24px;
    width: 18px;
    cursor: pointer;

    & > span {
      @apply bg-black;

      position: relative;
      display: block;
      width: 100%;
      height: 2px;
      border-radius: 3px;
      transition: all 0.3s;
    }

    & > span + span {
      margin-top: 4px;
    }

    &.is-closed > span:nth-child(1) {
      animation: ease 0.7s top-2 forwards;
    }

    &.is-closed > span:nth-child(2) {
      animation: ease 0.7s scaled-2 forwards;
    }

    &.is-closed > span:nth-child(3) {
      animation: ease 0.7s bottom-2 forwards;
    }
  }

  &-menu {
    @apply hidden lg:block lg:mr-auto lg:ml-6 relative;

    @screen lg {
      position: unset;
    }

    &-list {
      @apply text-xs font-semibold;

      & > li > span,
      & > li > a {
        @apply pl-4 border-l-4 border-transparent text-xs lg:text-sm font-semibold text-gray-900;

        &.Selected {
          @apply border-brand-600;
        }
      }

      &  a.Menu-item-link--desktop {
       display: none !important;

       @screen md {
        display: flex !important;
       }
      }

      & > li > .subNav {
        transition: max-height 0.3s ease-in-out;

        & > .subNav-content {
          & > .subNav-content-label {
            @apply hidden lg:block mr-8;
          }

          & > .subNav-content-links {
            width: 100%;

            @screen lg {
              max-width: 10.625rem;
            }

            &.justOneSubCategory {
              @screen lg {
                max-width: 18rem;
              }
            }

            & > li > .subNav-content-linksItem {
              @apply absolute hidden w-full h-full;

              top: 0;
              left: 40rem;

              @screen lg {
                min-width: 369px;
              }

              @screen xl {
                top: 0;
                left: 41rem;
                min-width: 48%;
              }

              @media (min-width: 1440px) {
                min-width: 61.4%;
              }

              @screen 3xl {
                min-width: 65.6%;
              }

              & > div {
                box-shadow: 99em 99em 0 99em var(--gray-50);
              }
            }

            & > li > a {
              @apply border-l-4 border-transparent text-xs font-semibold text-gray-900 relative;
            }

            & > li:hover {
              @apply bg-gray-50;

              & > a::after {
                position: absolute;
                left: 100%;
                width: 0;
                height: 0;
                background: transparent;
                border-top: 100px solid transparent;
                border-right: 100px solid transparent;
                border-bottom: 100px solid transparent;
                content: '';
              }

              & > .subNav-content-linksItem {
                @apply block;
              }
            }

            & > li > .Selected {
              @apply border-brand-600;
            }

            & > li > a + a {
              border-top-color: #e4e2e3;

              @apply border-t lg:border-t-0 lg:border-transparent;
            }
          }
        }
      }

      @screen lg {
        @apply flex justify-between items-center;

        min-height: var(--header-lg);

        & > li {
          @screen lg {
            @apply flex justify-between items-center pr-6;

            min-height: var(--header-lg);

            & > a.itemWrapper-link {
              @apply border-l-0 border-b-4 p-0 border-transparent flex justify-center items-center;

              height: var(--header-lg);
            }

            & > .subNav {
              @apply absolute w-full bg-white flex;

              top: var(--header-lg);
              left: 0;
              min-height: 0;
              max-height: 0;
              transition: 0.3s ease-in-out;
              transition-property: max-height, min-height, box-shadow;

              & > .subNav-content {
                @apply p-12 flex flex-wrap lg:flex-nowrap;

                & > .subNav-content-label {
                  @apply hidden lg:block text-brand-600 text-3xl uppercase font-brand lg:w-full font-normal;

                  @screen lg {
                    max-width: 373px;
                  }
                }

                & > .subNav-content-links {
                  @apply flex flex-col;

                  & > .Header-menuCategoriesSubGroupTitle {
                    @apply p-4 md:pr-1 py-2 border-l-4 border-transparent;

                    &:hover {
                      @apply bg-gray-50;

                      & > a {
                        @apply text-current;
                      }
                    }

                    &.Selected {
                      @apply border-brand-600;
                    }
                  }
                }
              }
            }
          }

          &:hover {
            @apply border-red-600 text-gray-900;

            & > div > a,
            .itemWrapper-link,
            & > div > span > a {
              @apply text-gray-900;
            }

            & >  a.itemWrapper-link {
              @apply border-red-600 text-gray-900;
            }

            & > .subNav {
              @apply max-h-screen;

              box-shadow: 0 99em 0 99em rgb(0 0 0 / 25%);

              @screen lg {
              }
            }
          }
        }
      }

      @screen xl {
        @apply text-sm;
      }
    }
  }

  &-menuCategoriesSub {
    @apply flex-grow h-full px-4 py-6 ml-8 gap-x-4 gap-y-4 bg-gray-50;

    @screen lg {
      column-gap: 66px;
    }
  }

  &-menuCategoriesSubGroup {
    @apply flex flex-col;

    width: fit-content;
    height: fit-content;
  }

  &-menuCategoriesSubGroupItem {
    @apply lg:p-4 lg:py-2 font-semibold lg:text-gray-500 border-gray-50 border-b lg:border-b-0 lg:border-l-2 lg:border-transparent flex flex-col lg:flex-row;

    &:hover {
      @apply bg-gray-50 text-gray-500;
    }

    & > .border {
      @apply hidden lg:block -ml-4 -my-2 mr-4 border-transparent;

      width: 0.1875rem;
    }

    &:focus {
      & > .border {
        @apply bg-brand-600 border-brand-600;
      }
    }

    &.active {
      @apply bg-gray-50;

      & > .border {
        @apply bg-brand-600 border-brand-600;
      }
    }
  }

  &-menuFolderSubGroupTitle {
    @apply block text-xs font-semibold md:pr-1 text-gray-900 w-full;

    &:hover {
      @apply text-gray-600;
    }
  }

  &-menuCategoriesSubGroupTitle {
    @apply p-4 py-2 text-xs font-semibold border-gray-50 border-b lg:border-b-0 border-l-4 border-transparent md:pr-1;

    &:hover {
      @apply text-gray-600;
    }
  }

  &-menuCategoriesSubGroupSubTitle {
    @apply p-4 py-2 text-xs text-gray-600 border-l-4 border-transparent md:pr-1 lg:pb-1 xl:py-2;

    &:hover {
      @apply text-gray-700;
    }
  }



  &-action {
    @apply flex text-gray-900 text-xxs;

    &-account {
      @apply lg:border-l lg:border-gray-200  lg:flex-col items-center lg:px-6 lg:py-4 justify-center hidden lg:flex;

      transition: 0.2s ease-out;

      & > .icon {
        @apply w-6 h-6 lg:w-4.5 lg:h-4.5 lg:mb-1 text-gray-900;
      }

      &:hover {
        @apply bg-gray-50 text-gray-900;
      }

      &:active,
      &:focus {
        @apply bg-gray-300;
      }
    }

    &-services {
      @apply hidden text-white bg-brand-600 justify-center;

      transition: 0.2s ease-out;

      @screen lg {
        @apply border-l border-gray-200 flex flex-col items-center px-6 py-4;
      }

      & > .icon {
        @apply w-6 h-6 lg:w-4.5 lg:h-4.5 lg:mb-1 fill-white text-white;
      }

      &:hover {
        @apply bg-brand-400 text-white;
      }

      &:active,
      &:focus {
        @apply bg-brand-500;
      }
    }

    &-servicesMobile {
      && {
        @apply absolute flex items-center flex-col font-semibold text-xs bottom-0 right-0 z-10 justify-between px-6 py-3 w-1/2 lg:hidden text-white bg-brand-600;

        display: flex !important;
        width: 50% !important;

        @screen lg {
          display: none !important;
        }

        & > span {
          @apply p-0;
        }

        & > .icon {
          @apply w-5 h-5 fill-current;
        }

        &--full {
          width: 100% !important;
        }

        &--left {
          @apply left-0 bg-white text-gray-900 border-t border-gray-100;

          display: flex !important;
          width: 50% !important;

          @screen lg {
            display: none !important;
          }
        }
      }
    }
  }

  &-toggle span {
    @apply bg-black;

    display: block;
    width: 18px;
    height: 2px;
    margin-bottom: 4px;
    transform: rotate(0deg);
    transition: 0.2s ease all;
  }

  &-toggle span:nth-child(3) {
    margin-bottom: 0;
  }

  &-logoContainer {
    @apply my-auto;

    margin-left: var(--action-max-size);

    @screen lg {
      margin-left: 0;
    }

    & > .icon {
      @apply w-10 h-10 lg:w-14 lg:h-14;
    }
  }


}

/* MM override */
.mm {
  /* --mm-spn-item-height: 46px; */
}

.mm-ocd-opened {
  .Header-toggle {
    & > span:nth-child(2) {
      animation: ease 0.7s scaled forwards;
    }

    & > span:nth-child(1) {
      animation: ease 0.7s top forwards;
    }

    & > span:nth-child(3) {
      animation: ease 0.7s bottom forwards;
    }
  }
}

.mm-spn.mm-spn--light {
  @apply bg-white;
}

.mm-ocd {
  top: var(--header-base);

  body.had-topbar & {
    top: calc(var(--header-base) + var(--topbar-height));
  }
}

.mm-ocd .mm-spn span:not(:last-child),
.mm-ocd .mm-spn a:not(:last-child) {
  @apply block;

}

.mm-spn span:not(:last-child)::after,
.mm-spn a:not(:last-child)::after,
.mm-ocd__backdrop,
.mm-spn.mm-spn--navbar.mm-spn--main::before,
.mm-spn li::before,
.mm-spn.mm-spn--navbar.mm-spn--main::after {
  display: none;
}

.mm-spn.mm-spn--navbar.mm-spn--main ul {
  top: 0;
}

.mm-spn.mm-spn--navbar::after {
  @apply text-xs text-gray-900 bg-gray-50 font-semibold flex items-center;

  padding: 14px 23px 14px 14px;
  padding-left: 40px;
  text-align: left;
  opacity: 1;
}

.mm-spn.mm-spn--navbar::before {
  display: none;
}

.mm-spn {
  @apply block;

  width: 100%;
  max-width: 100vw;
}

.mm-spn ul {
  width: 100%;
  max-width: 100vw;
  padding-right: 0;
}

.mm-spn span {
  display: flex;
  padding: 0;
}

.mm-spn span,
.mm-spn a {
  padding: 15px 16px 14px;
}

.mm-spn li::after {
  margin-left: 0;
  border-width: 1px;
  border-top-color: #e4e2e3;
}


.mm-spn span:not(:last-child),
.mm-spn a:not(:last-child) {
  display: inline-block;
  width: auto;
}

.mm-spn.mm-spn--light.mm-spn--navbar > svg {
  @apply block;

  top: calc((var(--mm-spn-item-height) / 2) - 10px);
  left: 1rem;
  pointer-events: none;
}

.mm-spn.mm-spn--light.mm-spn--navbar.mm-spn--main > svg {
  @apply hidden;
}

.mm-spn li.Header-agriLanding {
  @apply p-4;
}

.Header-agriLanding .btn--secondaryRed {
  @apply font-headline font-semibold select-none cursor-pointer text-center py-3 px-10 text-sm;
}
