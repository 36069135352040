.PopBanner {
  @apply bg-gray-900 py-3 sm:py-7 sticky bottom-0 left-0 right-0 text-white font-normal  px-4 sm:px-8 z-10 transform translate-y-full -mx-4 md:-mx-8 opacity-0 transition-transform;

  &-container {
    @apply flex items-center gap-6 justify-between;
  }

  &-title {
    @apply text-lg md:text-2xl;
  }

  &.is-visible {
    @apply transform-none opacity-100;
  }

  &.tooMuch {
    @apply bg-brand-600;

    .btn {
      @apply opacity-0
    }
  }
}
