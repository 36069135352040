.Home {
  &-cards {
    @apply divide-y md:divide-none border-gray-50 md:flex;

    @screen 3xl {
      & > div {
        max-height: 75vh;
        overflow: hidden;
      }
    }

    &-main {
      @apply md:w-2/3;
    }

    &-small {
      @apply mx-4 md:mx-0 py-8 md:w-1/3 md:p-0;
    }
  }

  &-culture {
    @apply text-white bg-gray-900 pb-14 lg:pb-28 lg:pl-12 lg:pt-28;

    & > h1 {
      @apply px-4 pt-12 pb-8 lg:p-0 text-mobile-xl lg:text-4xl lg:pb-10;
    }
  }

  &-selections {
    @apply px-4 pt-12 pb-14 lg:px-12 lg:py-28;

    &-content {
      @apply flex items-center mb-8;

      &-title {
        @apply text-center text-gray-900 xl:text-left text-mobile-xl lg:text-4xl;
      }

      &-button {
        @apply hidden ml-auto lg:flex text-xxs lg:text-xs lg:leading-5;

        &--mobile {
          @apply mx-auto mt-6 flex lg:hidden text-xxs lg:text-xs lg:leading-5;
        }
      }
    }
  }

  &-seo {
    & > .ServiceBloc {
      @screen md {
        min-height: 348px;
      }

      @screen lg {
        min-height: 502px;
      }

      @screen xl {
        min-height: 462px;
      }

      @screen 2xl {
        min-height: 480px;
      }

      @screen 3xl {
        min-height: 526px;
      }

      & > .tb-multiColumns {
        width: 100%;

        @apply flex flex-col-reverse md:flex-row;

        & > [data-tb-contain*='blockImage'] {
          @apply object-cover w-full md:w-1/2 m-0;
        }

        & > [data-tb-contain*='blockTitle'][data-tb-contain*='blockText'] {
          @apply flex flex-col items-center justify-center px-4 pt-10 pb-14 md:p-6 lg:p-12 xl:p-16 md:items-start md:w-1/2;

          @screen md {
            max-width: 50%;
          }

          & > .tb-blockTitle {
            @apply w-full m-0 text-mobile-xl lg:text-4xl;
          }

          & > .tb-blockText {
            @apply text-sm text-gray-600 lg:text-base;
          }

          & > .tb-blockSeparator {
            @apply my-2 lg:my-3;
          }
        }
      }
    }
  }
}
