.Breadcrumbs {
  &-list {
    @apply flex overflow-auto;

    & > .Breadcrumbs-button {
      @apply mr-4;
    }
  }

  &-button  {
    @apply font-semibold text-white flex hover:text-white btn whitespace-nowrap;

    padding: 8px 12px 8px 8px;
    font-size: 10px;
    line-height: 12px;

    @screen lg {
      @apply text-xs py-2 pr-4.5 pl-3.5;
    }

    border-radius: 16.4375rem;

    &-icon {
      @apply w-3 h-3 lg:w-5 lg:h-5 fill-current;

      margin-right: 0.458125rem;
      fill: white !important;
    }
  }
}
