.OnBoarding {
  &--modal {
    @apply fixed flex flex-col w-full bg-white overscroll-contain text-gray-900 inset-0 overflow-auto;

    z-index: 60;
    height: 100vh;
    transform: translateY(100vh);
    transition: 300ms ease-in-out;
    transition-property: transform, top, height;

    &.open {
      transform: translateY(0);
    }
  }
}
