.AlertInfos {
  @apply p-6 text-sm;

  @screen md {
    max-width: 42.5rem;
  }

  > .line {
    @apply mx-2 border-l bg-current border-current;

    width: 0.0625rem;
    height: 1rem;
    max-height: 2rem;
  }
}
