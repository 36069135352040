.AppLauncherList {
  &-track {
    @screen lg {
      @apply max-w-full w-full;
    }
  }

  & &-list {
    @apply flex;

    @screen lg {
      @apply flex-col max-w-full w-full;
    }

    > .splide__slide {
      @apply lg:mb-4;

      > .AppLauncher {
        @apply min-h-full;
      }
    }
  }

  &-listItem {
    @screen lg {
      @apply max-w-full mb-4 w-full;

      &:last-child {
        @apply mb-0;
      }
    }
  }
}
