.Footer {
  @apply relative text-gray-500 bg-gray-900;

  /* 14/18 */
  font-size: 0.875rem;
  line-height: 1.125rem;

  @screen xl {
    @apply text-sm;
  }

  &-content {
    @apply grid gap-y-12 px-4 pt-12 pb-10;

    @screen md {
      @apply pl-6 pt-16 pb-12;

      padding-right: 5.4375rem;
      column-gap: 6.4375rem;
    }

    @screen lg {
      @apply px-12 py-20;

      grid-template-columns: auto auto;
      column-gap: 5.5rem;
    }

    @screen xl {
      padding-top: 6.25rem;
    }

    @screen 3xl {
      @apply px-20;
    }
  }

  &-contentLeft {
    @apply flex justify-center items-center flex-col flex-wrap max-w-xs justify-self-center;

    @screen md {
      margin-right: -2.71875rem;
    }

    @screen lg {
      @apply justify-start items-start max-w-none;

      margin-right: 0;
    }

    @screen 3xl {
      @apply justify-self-start;
    }
  }

  &-contentLeftLogo {
    @apply w-14 h-14;

    @screen xl {
      @apply w-20 h-20;
    }
  }

  &-contentLeftButton {
    @apply mt-8 mb-6 md:my-8 text-xs;

    @screen xl {
      @apply text-sm leading-6;
    }
  }

  &-contentLeftList {
    @apply flex flex-wrap justify-center mb-6 max-w-xxs;

    & > a:nth-child(1),
    & > a:nth-child(2),
    & > a:nth-child(3),
    & > button {
      @apply mb-2 mr-4 lg:mr-auto xl:mb-2;
    }

    @screen lg {
      @apply flex-col mb-8;
    }

    @screen xl {
      @apply mb-6;
    }
  }

  &-contentLeftSocialNetwork {
    @apply flex w-full justify-center lg:justify-start;

    &-iconContainer {
      @apply w-9 h-9 overflow-hidden text-gray-500 bg-gray-800 rounded-full flex items-center justify-center mr-2;

      & > .icon {
        @apply fill-current;

        width: 1.1rem;
        height: 1.1rem;
      }

      &:hover {
        @apply bg-white text-brand-600;
      }
    }
  }

  &-contentRight {
    @apply grid grid-cols-2 gap-y-10;

    column-gap: 5.875rem;

    @screen md {
      @apply gap-y-0 grid-cols-4;
    }

    @screen lg {
      @apply gap-x-3;
    }

    @screen 2xl {
      @apply gap-x-12;
    }

    @screen 3xl {
      @apply justify-self-start;

      column-gap: auto;
    }
  }

  &-contentRightItemContainer {
    @apply flex flex-col;

    & > a {
      @apply mb-2;
    }
  }

  &-contentRightItemContainerLabel {
    @apply mb-4 text-white font-semibold whitespace-nowrap;
  }

  &-copyright  {
    @apply mx-4 pt-10 pb-32 flex flex-col border-t border-gray-600 text-xs;

    @screen md {
      @apply flex-row py-8 mx-6;
    }

    @screen lg {
      @apply py-8 mx-12;
    }

    @screen xl {
      @apply py-8.5;
    }

    @screen 3xl {
      @apply mx-20;
    }
  }

  &-copyrightList {
    @apply flex flex-col lg:block;

    @screen md {
      line-height: 1.125rem;
      max-width: 27.5rem;
    }

    @screen lg {
      max-width: 100%;
    }

    & > .copyright {
      @apply whitespace-nowrap lg:ml-10;
    }

    & > a {
      @apply mb-2 md:mb-0 lg:ml-3;

      &:first-child {
        @apply ml-0;
      }
    }
  }

  &-badge {
    @apply absolute right-0 w-32 h-8 bottom-14 md:bottom-10 lg:bottom-6;

    @screen xl {
      bottom: 1.8125rem;
    }
  }
}

.Footer-copyrightList > a,
.Footer-contentLeftList > a,
.Footer-contentRightItemContainer > a {
  @apply lg:mr-auto;

  padding-bottom: 1px;

  &:hover {
    @apply underline;
  }
}
