.wysiwyg {
  @apply text-sm lg:text-base text-gray-600;

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: bold;

    @apply mb-4 text-gray-900;
  }

  h1 {
    @apply text-3xl;
  }

  h2 {
    @apply text-2xl;
  }

  h3 {
    @apply text-lg;
  }

  h4 {
    @apply text-base font-semibold;
  }

  h5 {
    @apply text-sm;
  }

  hr {
    @apply border-t border-white border-opacity-50 my-4;
  }

  p {
    @apply mb-4 text-sm lg:text-base text-gray-600;
  }

  ul,
  ol {
    @apply list-none mb-6 block text-gray-600;

    li {
      @apply block pl-4 relative;
    }

    p {
      color: inherit;
      font-size: inherit;
    }
  }

  ul {
    li::before {
      @apply bg-gray-900 absolute left-0;

      top: 0.625rem;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      content: '';

      @screen lg {
        @apply top-3;
      }
    }
  }

  ol {
    counter-reset: ol-counter;

    li {
      counter-increment: ol-counter;

      &::before {
        @apply absolute left-0;

        top: 1px;
        content: counter(ol-counter) ". ";
      }
    }
  }

  blockquote {
    @apply my-6;

    position: relative;
    display: inline-block;
    padding: 0 2rem;
    text-align: center;

    p {
      margin-bottom: 0;
    }

    &::after,
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      font-size: 3rem;
      font-style: italic;
      line-height: 1rem;
      content: '“';

      @apply text-brand-600;
    }
  }

  a:not([class*='btn'], [class*='alert']) {
    @apply underline;

    &:hover {
      @apply text-brand-600;
    }
  }

  table {
    @apply w-full mb-6 mt-6 border border-gray-100 border-opacity-25;
  }

  th,
  td {
    text-align: left;

    @apply border-b border-gray-100 border-opacity-25 p-4;
  }

  thead th {
    @apply text-xl bg-gray-500;
  }

  tbody tr:nth-child(odd) > td,
  tbody tr:nth-child(odd) > th {
    @apply bg-gray-300;
  }

  .tb-blockButtonWrapper {
    @apply my-6 text-center;
  }

  .tb-blockButton {
    @apply inline-block;
  }

  .tb-blockHighlight {
    @apply my-6;

    p:last-child {
      margin-bottom: 0;
    }
  }

  .tb-blockVideo {
    @apply my-6;

    width: 800px;
    max-width: 100%;
    height: 400px;
  }

  .tb-blockText {
    @apply mb-6;
  }

  .tb-blockImage {
    @apply block mb-6 mx-auto;
  }

  .tb-blockTryout {
    h2 {
      @apply text-white;
    }

    ul {
      li {
        @apply p-0;

        &::before {
          display: none;
        }
      }
    }
  }

  iframe {
    max-width: 100%;
  }
}
