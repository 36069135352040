.HeaderSearchAction {
  &-search {
    @apply lg:border-l lg:border-gray-200 flex lg:flex-col items-center lg:p-6;

    & > input {
      @apply hidden xl:block border-none text-sm text-gray-500 -ml-6 w-full pl-4;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        box-shadow: 0 0 0 30px white inset !important;
      }

      width: 16.25rem;

      &:focus,
      &:active {
        border: none;
        outline: none;
        box-shadow: none;
      }

      &.active {
        @apply block;
      }
    }

    & > .iconContainer > .icon {
      @apply w-6 h-6 lg:w-4.5 lg:h-4.5 lg:mb-1;

      @screen xl {
        @apply absolute right-4.5 w-6 h-6;

        top: calc(50% - (1.5rem / 2));
      }
    }

    &-label {
      @apply hidden lg:block xl:hidden;

      &.disable {
        @apply hidden;
      }
    }
  }

  &-actionSearchDropdownWrapper {
    @apply absolute left-0 w-full ;

    top: var(--header-base);
    max-height: 0;
    overflow-y: auto;
    transition: 300ms ease-out;
    transition-property: max-height;


    @screen xl {
      @apply top-20;

      overflow: hidden;
    }

    &.active {
      max-height: 100vh;

      @screen xl {
        box-shadow: 99em 99em 0 99em rgb(0 0 0 / 25%);
      }
    }
  }

  &-actionSearchDropdown {
    @apply flex flex-col px-4 py-6 border-gray-200 divide-y xl:divide-y-0 xl:divide-x bg-gray-50;

    padding-bottom: 150px;

    @screen xl {
      @apply flex-row px-12 pt-10 pb-12;
    }
  }

  &-actionSearchDropdownItemImage {
    @apply object-cover;

    width: 7.5rem;
    height: 5.75rem;
  }

  &-overlay {
    @apply absolute bg-white w-full flex items-center;

    top: 0;
    left: 0;
    transform: translateX(-100rem);
    opacity: 0;
    transition: 500ms ease-out;
    transition-property: transform, opacity;

    & > input {
      @apply border-none text-sm text-gray-500 w-full pl-4;

      height: var(--header-base);

      &:focus,
      &:active {
        border: none;
        outline: none;
        box-shadow: none;
      }

      &.active {
        @apply block;
      }
    }

    &.active {
      box-shadow: 99em 99em 0 99em var(--gray-50);
      transform: translateX(0);
      opacity: 1;
    }
  }

  &-searchAllButton {
    @apply pb-2 ml-auto border-b hover:text-gray-500 hover:border-transparent;
  }
}
