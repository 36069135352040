.OAD {
  min-height: 590px;
  padding: 48px 16px;

  @screen lg {
    min-height: 400px;
    padding: 64px 48px;
  }

  @screen 3xl {
    padding: 4rem 5rem;
  }

  &-noFarm {
    @apply h-full w-full flex flex-col px-6 my-auto;

    &Label {
      @apply text-sm text-center;
    }
  }
}

.errorLink {

}

.OADFarmCard {
  @apply flex flex-1 items-center w-full hover:text-gray-900;

  &-image {
    @apply hidden lg:block w-full mr-4;

    max-width: 12.5rem;
  }

  &-content {
    @apply flex flex-grow items-center py-6 px-4;

    &Header {
      @apply flex flex-col gap-y-1;

      & > small:first-of-type {
        max-width: 360px;
      }
    }

    &HeaderStatus {
      @apply flex gap-x-1 items-center text-accent-400 text-xxs font-semibold mt-1 lg:hidden;

      &::before {
        width: 4px;
        height: 4px;
        background: #2A84C4;
        border-radius: 100%;
        content: '';
      }
    }
  }

  &-buttonContainer {
    @apply hidden lg:flex mr-4;
  }


  &--wrapper {
    @apply flex items-center bg-white relative animate-fade-in-left;
  }
}
