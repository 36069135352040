.Modal {
  &-overlay {
    @apply fixed inset-0 flex justify-center items-end lg:items-center;

    z-index: 60;
    background: rgb(0 0 0 / 60%);
  }

  &-container {
    @apply bg-white;

    max-width: 80%;
    max-height: 80vh;
    padding: 72px 24px 40px;
    overflow-y: auto;
    border-radius: 2px;

    @screen lg {
      padding: 64px 40px 40px;
    }
  }

  &-header {
    @apply flex justify-between items-center;
  }

  &-title {
    @apply mt-0 mb-4 text-lg lg:text-2xl font-semibold text-gray-900;
  }

  &-close {
    @apply absolute bg-transparent border-none p-2 top-4 right-4 outline-none;

    position: absolute;
    transition: 0.2s ease-out;

    > svg {
      pointer-events: none;
    }

    &:hover,
    &:focus {
      opacity: 0.5;
    }
  }

  &-content p,
  &-content {
    @apply text-sm text-gray-600;
  }
}

@keyframes mmfadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-10%);
  }
}

.micromodal-slide {
  display: none;
}

.micromodal-slide.is-open {
  display: block;
}

.micromodal-slide[aria-hidden='false'] .Modal-overlay {
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden='false'] .Modal-container {
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden='true'] .Modal-overlay {
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden='true'] .Modal-container {
  animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide .Modal-container,
.micromodal-slide .Modal-overlay {
  will-change: transform;
}
