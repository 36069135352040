.DownloadContent {
  @apply flex flex-col justify-end lg:p-4 bg-white hover:bg-gray-50;

  height: 100%;
  max-width: 15rem;

  @screen lg {
    max-width: 17rem;
  }

  & > .btn--secondary {
    max-width: 13.25rem;
  }

  &-image {
    @apply flex;

    height: 15rem;

    & > img {
      @apply object-cover mt-auto;
    }
  }
}
