.BestVarietiesForm {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -1rem;
  padding: 2em;
  background-color: #fff;

  @screen sm {
    margin: 0 -2rem;
  }

  @screen lg {
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 100vh;
    padding: 0;
  }

  &-image {
    display: none;

    @screen lg {
      display: block;
      height: 100%;
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &-rightBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;

    @screen lg {
      padding: 8rem;
    }
  }

  &-title {
    @apply text-brand-600;

    margin-bottom: 40px;
    text-align: center;

    @screen lg {
      text-align: left;
    }
  }

  &-form {
    display: grid;
    gap: 10px;
    width: 100%;

    & > button {
      margin-top: 20px;
      margin-left: auto;
    }
  }
}
