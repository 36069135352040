.Card {
  @apply md:relative flex md:block overflow-hidden;

  margin: 0;
  padding: 0;

  &:hover {
    .Card-content {
      @apply bg-gray-50 text-gray-900;
    }

    .Card-image > img,
    .Card-image > div:first-child > img {
      transform: scale(1.05) translateX(-0.4rem);
    }
  }

  &.hasMarginTop {
    @apply lg:mt-12;
  }

  &-image {
    @apply mr-6;

    min-width: 7.5rem;
    max-width: 7.5rem;

    & > div:first-child {
      min-width: 7.5rem;
      max-width: 7.5rem;
      transition: max-width 0.8s ease-in-out;

      @screen md {
        max-width: 100%;
      }

      & > img {
        transition: transform 0.5s ease-in-out;
      }
    }

    & > img {
      transition: transform 0.5s ease-in-out;
    }

    @screen md {
      @apply mr-0;

      max-width: 100%;
    }
  }

  &-content {
    @apply p-0 m-0 flex flex-col;

    transition: background-color 0.3s ease-in-out;

    @screen md {
      @apply absolute bottom-0 left-0 w-4/5 px-4 pt-4 pb-5 bg-white;
    }

    &-label {
      @apply font-semibold text-xxs mb-1.5;
    }

    &-title {
      @apply text-xs;

      @screen lg {
        @apply text-base;
      }
    }
  }

  &.main-small {
    @apply mb-4;

    @screen md {
      @apply mb-0;
    }

    @screen 3xl {
      @apply h-1/2;
    }
  }

  &.main {
    @apply mb-4 flex-col;

    & > .Card-image {
      min-width: 100%;
      max-width: 100%;

      & > div {
        max-width: 100%;
      }
    }

    & > .Card-content {
      @apply px-4 py-6;

      & > .Card-content-title {
        @apply text-mobile-xl;
      }
    }

    @screen md {
      @apply mb-0;
    }

    @screen 3xl {
      @apply h-full;
    }
  }

  &.list {
    @apply mb-4;

    @screen md {
      @apply mb-0;

      width: 33%;
    }
  }
}
