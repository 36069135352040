.AgriLanding {
  @apply bg-gray-50 flex-col items-start flex mb-10 lg:hidden;

  &Wrapper {
    @apply hidden lg:block;

    @screen lg {
      max-width: 400px;
    }
    @screen xl {
      max-width: 460px;
    }

    .AgriLanding {
      @apply flex h-full mb-0 ;
    }
  }

  &-title {
    @apply text-base mb-2 lg:mb-4 lg:text-2xl;
  }

  &-desc {
    @apply text-xs lg:text-sm mb-4 lg:mb-6 text-gray-600 font-normal;
  }

  &-img {
    @apply hidden lg:block max-h-half w-full;

    img {
      @apply w-full h-full object-cover;
    }
  }

  &-contain {
    @apply p-4 py-8 lg:p-8 flex flex-col items-start;
  }

  .btn {
    @apply font-headline font-semibold select-none cursor-pointer text-center py-3 px-10 text-sm bg-brand-600 text-white;
  }
}


