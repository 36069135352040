.OverlayContact {
  @apply fixed flex flex-col w-full bg-white overscroll-contain text-gray-900 max-w-screen;

  top: 0;
  right: 0;
  left: 0;
  z-index: 60;
  height: 100vh;
  transform: translateY(150vh);
  transition: 300ms ease-in-out;
  transition-property: transform, top, height;

  @screen md {
    max-width: 100%;
  }

  &.open {
    box-shadow: -20em -20em 0 99em rgb(0 0 0 / 25%);
    transform: translateY(0);
  }

  &-closeIcon {
    position: absolute;
    top: theme('spacing[4.5]');
    right: theme('spacing[4]');

    @screen lg {
      position: fixed;
      top: theme('spacing[8]');
      right: theme('spacing[8]');
    }
  }

  &-content {
    @apply flex overflow-y-auto;

    @screen lg {
      height: 100%;
      max-height: 100%;
    }
  }

  &-formContainer {
    @apply relative flex flex-col;

    height: 100%;
    max-height: 100%;
    padding: 2.875rem 1rem;
    overflow-y: auto;

    @screen lg {
      @apply p-20;
    }

    &:not(.active) {
      @apply hidden;
    }
  }

  &-form {
    @apply flex-grow flex flex-col;
  }

  &-feedback {
    @apply flex flex-col items-center justify-center mx-auto text-center animate-fade-in-left;

    &:not(.active) {
      @apply hidden;
    }
  }
}
