:root {
  --dot-size: 0.4375rem;
}

.tabs {
  @apply flex flex-col pt-16 text-white w-full;

  &-title {
    @apply flex-1 px-8 md:px-20 mb-16 text-2xl text-white;
  }

  &-content {
    @apply justify-self-end relative;

    max-height: 48px;

    @screen lg {
      max-height: 65px;
    }

    &.no-js {
      @apply visible;
    }
  }

  &-list {
    @apply flex px-8 overflow-x-hidden;

    padding-top: 15px;
    top: -15px;
    margin-bottom: -15px;
    position: relative;

    @screen md {
      @apply pl-20;
    }

    &-item {
      position: relative;
    }

    .splide__list {
      @apply flex;
    }

    &.innovation {
      @apply pl-6 xl:pl-20 mb-0;
    }

    &.noArrow {
      @apply pl-4 md:pl-6 lg:pl-12 3xl:pl-20;
    }
  }
}

.tabs-dot {
  @apply absolute rounded-full;

  left: 50%;
  top: calc(calc(-1 * var(--dot-size)) / 2);
  transform: translateX(-50%);
  width: var(--dot-size);
  height: var(--dot-size);
}

.arrow-bg--next {
  right: 0;
  width: 6.875rem;
  height: 3rem;
  position: absolute;
  z-index: 10;
  background: linear-gradient(270.32deg, rgba(0, 0, 0, 0.15) 39.52%, rgba(0, 0, 0, 0) 81.86%);

  @screen lg {
    height: 4rem;
  }
}

.arrow-bg--prev {
  left: 0;
  width: 6.875rem;
  height: 3rem;
  position: absolute;
  z-index: 10;
  background: linear-gradient(-270.32deg, rgba(0, 0, 0, 0.15) 39.52%, rgba(0, 0, 0, 0) 81.86%);

  @screen lg {
    height: 4rem;
  }
}

.splide__arrow {
  @apply text-white bg-corn-silage-dark;

  &.corn-silage {
    @apply bg-corn-silage-dark;
  }

  &.grape-corn {
    @apply bg-grape-corn-dark;
  }

  &.sunflower {
    @apply bg-sunflower-dark;
  }

  &.colz {
    @apply bg-colz-dark;
  }

  &.forages {
    @apply bg-forages-dark;
  }

  &.cereals {
    @apply bg-cereals-dark;
  }

  &.protein-crops {
    @apply bg-protein-crops-dark;
  }

  &.brand {
    @apply bg-brand-400;
  }

  &.gray {
    @apply bg-gray-900;
  }

  &--prev svg {
    transform: none;
  }
}
