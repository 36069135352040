.tab {
  @apply text-white font-semibold text-xs whitespace-nowrap;

  line-height: 18px;
  padding: 14px 12px;
  min-width: 7.5625rem;

  @screen lg {
    @apply text-sm;

    padding: 1.3125rem 0.8125rem;
  }


  &:disabled,
  &[disabled] {
    @apply bg-gray-900 hover:bg-gray-900 text-gray-500 pointer-events-none;
  }
}
