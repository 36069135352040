.PopinDown {
  @apply transition-transform fixed inset-x-0 bottom-0 z-10 px-4 pb-6 text-white bg-black pt-14 md:px-12 md:pt-10 md:pb-10;

  transform: translateY(100%);

  &.visible {
    transform: translateY(0);
  }

  &.hidden--definitive {
    display: none !important;
  }
}
