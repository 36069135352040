.SubscriptionItem {
  @apply flex flex-col lg:flex-row border-t py-6;

  &:last-child {
    @apply border-b mb-0;
  }

  &-wrapImage {
    @apply mb-6 lg:mr-6 lg:mb-0;

    min-width: 180px;
    width: 180px;
  }

  &-content {
    @apply mb-6 lg:flex-grow lg:mb-0;
  }

  &-title {
    @apply text-base lg:text-2xl font-normal mb-2;
  }

  & &-description {
    @apply text-sm lg:text-base;

    * {
      @apply text-sm lg:text-base;
    }

    > *:last-child {
      @apply mb-0;
    }
  }

  &-actions {
    @apply lg:text-right lg:ml-6;

    min-width: 300px;
  }

  &-actionsList {
    li {
      @apply mb-3;

      &:last-child {
        @apply mb-0;
      }
    }
  }

  &-modal {
    @apply text-left;

    .Modal-container {
      @apply max-w-xl;
    }
  }
}
