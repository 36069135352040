.LeftNavItem {
  @apply p-3 font-semibold text-gray-500 border-l-2 border-transparent text-xxs flex;

  max-width: 15rem;

  & > .border {
    @apply -ml-3 -my-3 mr-3 border-transparent;

    width: 0.1875rem;
  }

  &:hover {
    @apply bg-gray-50 text-gray-500;

    & > .border {
      @apply bg-brand-600 border-brand-600;
    }
  }
}
