.FullCycleOfPlant {
  @screen xl {
    @apply mx-auto;

    max-width: 1184px;
  }

  &.no-js {
    @apply visible;

    .splide__list {
      @apply flex;

      li {
        @apply mr-4;
      }
    }
  }

  & > .splide__pagination {
    bottom: -38px;

    & > li + li {
      @apply ml-3;
    }

    & > li > .splide__pagination__page {
      @apply w-1.5 h-1.5;

      &.is-active {
        @apply bg-brand-600;

        transform: none;
      }
    }
  }
}
