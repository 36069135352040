:root {
  --brand-600: #EE002D;
  --brand-500: #B80023;
  --brand-400: #D10028;
  --brand-300: #FFA8B9;
  --brand-200: #FFEBEE;
  --brand-100: #FFF5F7;

  --corn-silage: #008635;
  --corn-silage-dark: #006B2A;

  --grape-corn: #8F6E50;
  --grape-corn-dark: #806247;
  --sunflower: #FF9F36;
  --sunflower-dark: #FF8A0A;
  --colz: #5A009E;
  --colz-dark: #490080;
  --forages: #7FC120;
  --forages-dark: #73AF1D;
  --cereals: #D79A4A;
  --cereals-dark: #C4822B;
  --protein-crops: #4A8EAB;
  --protein-crops-dark: #427F99;

  --gray-900: #272526;
  --gray-800: #2F2D2E;
  --gray-700: #3C393B;
  --gray-600: #605C5D;
  --gray-500: #A39F9F;
  --gray-400: #DBDBDB;
  --gray-300: #E8E8E8;
  --gray-200: #EEEDED;
  --gray-100: #F2F2F2;
  --gray-50: #F7F7F7;

  --accent-400: #2A84C4;
  --accent-300: #D1E6F5;
  --accent-200: #E6F1FA;
  --accent-100: #F2F8FC;
}