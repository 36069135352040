.Seeds {
  &-categories {
    @apply px-4 py-12;

    @screen md {
      @apply px-6 py-16;
    }

    @screen lg {
      padding: 100px 48px;
    }

    @screen xl {
      padding: 100px 150px;
    }
  }
}
