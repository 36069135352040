.AccountHeader {
  @apply bg-gray-900 text-white flex flex-col md:flex-row;

  &-content {
    @apply px-4 pt-6 pb-0 md:p-12 md:flex md:flex-col md:justify-between;
  }

  &-action {
    @apply flex mb-10;

    @screen md {
      margin-bottom: 7.75rem;
    }

    > * {
      @apply ml-4 whitespace-nowrap;

      padding: 6px 12px 8px 8px;
      font-size: 10px;
      line-height: 12px;

      @screen lg {
        @apply text-xs py-2 pr-4.5 pl-3.5;
      }
    }

    > :first-child {
      @apply ml-0;
    }
  }

  &-title {
    @apply mb-10 text-mobile-xl;

    @screen lg {
      @apply text-4xl mb-0;
    }

    @screen xl {
      @apply text-5xl;
    }
  }

  &-subContent {
    /* TODO add scroll */
    @apply flex lg:ml-auto;

    &-meteo,
    &-advisers {
      @apply lg:w-auto lg:h-full p-6 lg:p-8 text-center;

      padding-bottom: 2.9375rem;
    }

    &-meteo {
      /* TODO: verify at the end */
      @apply bg-gray-800 flex-grow;

      background-color: #343233;

      @screen md {
        flex-grow: initial;
        min-width: 13.75rem;
      }

      &-location {
        @apply text-xs mb-4 block lg:text-sm;

        @screen lg {
          margin-bottom: 3.6875rem;
        }

        @screen 2xl {
          margin-bottom: 6.375rem;
        }
      }

      &-temperature {
        @apply flex flex-col-reverse md:flex-row justify-center items-center;

        & > .celsius {
          @apply text-2xl md:text-6xl md:mr-2;

          @screen md {
            font-size: 2.5rem;
            line-height: 3.125rem;
          }
        }

        & > .icon {
          @apply w-14 h-14 rounded-full bg-gray-600 flex items-center justify-center text-white mb-2 md:mb-0;
        }
      }

      &-info {
        @apply mt-8.5 md:mt-12;

        @screen lg {
          margin-top: 3.6875rem;
        }

        @screen 2xl {
          margin-top: 3.9375rem;
        }

        & > .info-item {
          @apply text-xxs lg:text-xs font-semibold;

          & > span {
            @apply font-normal text-gray-500;
          }
        }

        & > .info-item + .info-item {
          @apply mt-1;
        }
      }
    }

    &-advisers {
      @apply flex flex-grow flex-col overflow-hidden bg-gray-700 relative;

      background-color: #413e40;

      @screen xl {
        max-width: 100%;
      }

      &-contacts {
        @apply flex flex-1 xl:divide-x -mx-6 md:-mx-8;

        &-contact {
          @apply flex gap-y-6 flex-col px-3 h-full md:px-8;

          &-adviser {
            @apply flex flex-col items-center mb-auto;

            &-avatar {
              @apply w-14 h-14 rounded-full overflow-hidden mb-2;

              & > img {
                @apply w-full h-full object-cover;
              }
            }

            & > h3 {
              @apply mb-1 text-xs;
            }

            & > small {
              @apply text-gray-500 text-xxs;

              max-width: 8.5rem;
            }
          }

          &-action {
            @apply flex flex-col items-center;

            & > button {
              @apply text-xxs;

              padding: 5px 10px;

              & > svg {
                margin-right: 7.33px;
              }
            }

            & > button + button {
              @apply mt-3;
            }
          }
        }
      }

      & > .splide__pagination {
        & > li > .splide__pagination__page {
          @apply w-1 h-1;

          &.is-active {
            @apply bg-brand-600;

            transform: none;
          }
        }
      }

      & > .splide__arrows {
        background: blue;

        & > .splide__arrow--prev {
          visibility: hidden;
        }

        & > .splide__arrow--next {
          @screen xl {
            visibility: hidden;
          }
        }
      }
    }
  }
}
