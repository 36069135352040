.OAD-newFarm {
  @apply fixed w-screen h-screen bg-white overflow-y-scroll flex;

  top: 100%;
  left: 0;
  z-index: 60;
  max-height: 100vh;
  transition: top ease-out 300ms;

  &.active {
    top: 0;
  }

  &Content {
    @apply flex-grow flex flex-col px-4 lg:px-20;

    max-width: 100vw;
  }

  &Image {
    @apply w-1/2 h-full object-cover hidden lg:block;
  }

  &Title {
    @apply mt-1 mb-6 text-3xl text-brand-600;
  }

  .floating-label {
    @apply text-gray-500;
  }

  &CultureType {
    @apply flex;

    &Label {
      @apply flex w-1/4;

      line-height: 18px;

      & + .OAD-newFarmCultureTypeLabel {
        label {
          @apply border-l;
        }
      }

      label {
        @apply py-4 w-full h-full text-center border-t border-b border-gray-200 text-xs text-gray-600 whitespace-nowrap cursor-pointer flex-1 justify-center;
      }

      &:first-of-type {
        label {
          @apply border-l;

          border-radius: 4px 0 0 4px;
        }
      }

      &:last-of-type {
        label {
          @apply border-r;

          border-radius: 0 4px 4px 0;
        }
      }
    }

    &Radio {
      position: absolute;
      left: -100%;
      opacity: 0;

      &:checked  + label {
        @apply border-gray-900 border;
      }

      &:disabled ~ label {
        @apply text-gray-500 border-gray-200;
      }
    }
  }

  &OurCulture {
    @apply flex flex-col animate-fade-in-left;
  }

  &Footer {
    @apply flex py-8 mt-8 mb-36 lg:mb-0 border-t border-gray-200 animate-fade-in-left;
  }
}
