.Login {
  @apply flex flex-col justify-center py-4 md:py-10 m-auto;

  max-width: 372px;
  height: calc(100vh - var(--header-base));

  @screen md {
    height: auto;
  }

  &-form {
    @apply flex-grow flex flex-col justify-center;
  }

  &-noAccount {
    @apply md:mt-24;
  }
}
