.Account-apps {
  @apply px-4 -mx-4 lg:-mx-8 pt-14;

  width: 100vw;
  padding-bottom: 5.25rem;

  @screen lg {
    padding: 3.5rem 3.9375rem 5.25rem 3rem;
  }

  @screen 3xl {
    padding-right: 5.9375rem;
    padding-left: 5rem;
  }
}
