.SearchHeader {
  @apply px-4 md:px-12 bg-gray-900 text-white;

  padding-top: 3.5rem;

  @screen md {
    padding-top: 6.25rem;
  }

  &-content {
    @apply mb-10;
  }

  &-title {
    @apply md:text-5xl text-mobile-xl;
  }

  &-list {
    @apply flex overflow-x-auto -mx-4 md:ml-0;
  }

  &-listItem {
    & > .tab.active {
      @apply bg-gray-800;
    }
  }
}
