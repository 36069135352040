.AgritestingSteps {
  .StepsList-item {
    max-width: calc(100% - 100px);
    flex: 1 0 auto;

    @screen xl {
      max-width: auto;
      flex: 1;
    }
  }
}
