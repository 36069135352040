.VarietyProduct {
  @apply flex flex-col px-10 sm:border-l-2 border-gray-200 border-0 mb-8 sm:mb-0 transition-colors hover:bg-white focus-within:bg-white;


  &:first-child {
    @apply border-0 lg:border-l-2;
  }

  @screen 2xl {
    min-width: 320px;
  }

  &-title {
    @apply text-base font-semibold mb-5 hover:text-gray-900;
  }

  &-type {
    @apply text-xs text-gray-600;
  }

  &-image {
    @apply block mx-auto mt-auto mb-8;

    & img {
      height: 168px;
      object-fit: cover;
      object-position: center;
    }
  }
}
