.AgritestingListing {
  @apply divide-y;
  &-item {
    display: grid;
    gap: 1rem;
    @apply py-6;

    grid-template-columns: 170px 1fr;
    grid-template-areas:
      'img infos'
      'description description'
      'date actions';

    @screen xl {
      grid-template-rows: 50px auto;
      grid-template-columns: 170px 300px 1fr 200px;
      grid-template-areas:
        'img infos description actions'
        'img infos description date';
    }
    &-img {
      grid-area: img;
    }
    &-infos {
      grid-area: infos;
    }
    &-description {
      grid-area: description;
      align-self: start;
      @screen xl {
        max-width: 375px;
        margin: 0 0 0 50px;
      }
    }
    &-actions {
      grid-area: actions;
    }
    &-date {
      grid-area: date;
    }
  }
}
