.Checkbox,
[data-editorblocktype='Field-checkboxList'] input[type='checkbox'] {
  @apply w-4 h-4;

  border-color: #e3e3e3;
  border-radius: 0.25rem;

  &:hover {
    cursor: pointer;
  }

  &:active,
  &:checked {
    color: var(--brand-600);

    &:focus {
      @apply outline-none shadow-none;

      box-shadow: none;
    }
  }

  &:disabled + label {
    @apply text-gray-500;
  }

  &:focus {
    @apply outline-none shadow-none;

    box-shadow: none;
  }
}
