.LeftNavDropDown {
  @apply border-t border-gray-200;

  max-width: 21.4375rem;
  cursor: pointer;

  @screen md {
    max-width: 100%;
  }

  &-item {
    @apply flex justify-between py-6;
  }

  &:last-child {
    @apply border-b;
  }

  & + .LeftNavDropDown {
    @apply border-t;
  }

  &-label {
    /* important because of wysiwyg, override it. */
    margin: 0 !important;
    color: var(--gray-900) !important;
    font-size: 1rem/* 16px */ !important;
    font-weight: 600 !important;
    line-height: 1.375rem/* 22px */ !important;
    max-width: 17.625rem !important;

    @screen md {
      max-width: max-content !important;
    }

    @screen lg {
      font-size: 1.25rem/* 20px */ !important;
      line-height: 1.5rem/* 24px */ !important;
    }
  }

  &-icon {
    @apply w-6 h-6 fill-current text-brand-600;
  }

  &-content {
    @apply hidden;

    &.open {
      @apply block cursor-default;
    }

    &:after {
      @apply block mb-6;

      content: '';
    }
  }
}
