.Search {
  @apply -mx-4 md:-mx-8;
}

.SearchResults {
  @apply px-5 py-12 lg:px-0;

  &-items {
    @apply flex-wrap gap-y-8 hidden animate-fade-in-down w-full;

    &[data-type="tryouts"] {
      @apply px-12;

      & > div {
        width: 100%;
      }
    }

    &.active {
      @apply flex;
    }

    & > .SingleProduct {
      @apply w-1/2 border-l border-gray-200;

      @screen lg {
        @apply w-1/4;

        &:nth-child(5) {
          @apply border-l-0;
        }
      }

      &:first-child,
      &:nth-child(3) {
        @apply border-l-0;
      }

      &:first-child {
        @screen lg {
          @apply border-l-0;
        }
      }

      &:nth-child(3) {
        @screen lg {
          @apply border-l border-gray-200;
        }
      }
    }

    & > .Rebound {
      @apply w-full;

      & > .selected {
        height: 42.8125rem;
      }
    }

    &[data-type="contents"] {
      @apply px-12;
    }
  }
}
