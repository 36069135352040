.alert {
  @apply p-6 text-sm bg-accent-200 text-accent-400;

  p {
    color: inherit;
  }

  a& {
    transition: 0.2s ease-out;

    &:hover {
      @apply text-accent-400 bg-accent-300;
    }
  }

  a,
  .btn--link {
    &,
    &:visited {
      @apply text-current border-current;
    }

    &:visited:hover,
    &:hover {
      @apply text-current;
    }
  }

  &--danger {
    @apply bg-red-100 text-red-500;

    a&:hover {
      @apply text-red-500 bg-red-200;
    }
  }

  &--warning {
    @apply bg-yellow-100 text-yellow-500;

    a&:hover {
      @apply text-yellow-500 bg-yellow-200;
    }
  }

  &--success {
    @apply bg-green-100 text-green-600;

    a&:hover {
      @apply text-green-600 bg-green-200;
    }
  }

  &--mini {
    @apply px-3 py-2 text-xs;
  }
}

.alert--wrapper {
  @apply py-20 mx-auto;

  max-width: 980px;
}
