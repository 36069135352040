.Map {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 60;
  width: 100%;
  height: 100vh;
  transform: translateX(-100%);
  transition: transform 0.3s ease;

  &.is-open {
    transform: translateX(0%);
  }

  &-closeIcon {
    @apply lg:hidden;

    position: absolute;
    top: theme('spacing[4.5]');
    right: theme('spacing[4]');
    z-index: 60;

    @screen lg {
      display: none;
    }
  }

  &-preview {
    @apply lg:hidden relative;

    &Map {
      width: 100%;
      min-height: 13.125rem;
    }

    button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @screen lg {
    position: relative;
    z-index: 0;
    height: 685px;
    transform: translateX(0%);
  }
}

.Map-labels {
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;

  @apply z-20;
}

.Map-marker {
  --marker-color: white;
  --marker-color-hover: gray;

  width: 1.5rem;
  height: 2rem;
  background: var(--marker-color);
  clip-path:
    path(
      'M12 30.4634C12 30.4634 24 19.1828 24 12.3578C24 5.53277 18.6274 0 12 0C5.37258 0 0 5.53277 0 12.3578C0 19.1828 12 30.4634 12 30.4634Z'
    );

  &:hover {
    background: var(--marker-color-hover);
    cursor: pointer;
  }
}

.Map-marker--red {
  --marker-color: var(--brand-600);
  --marker-color-hover: var(--brand-500);
}

.Map-marker--gray {
  --marker-color: #a09f9f;
  --marker-color-hover: #a09f9f;
}

.Map-marker--dark {
  --marker-color: var(--gray-900);
  --marker-color-hover: var(--gray-700);
}

.MapOverlay {
  @apply inset-0 fixed bg-white z-50;

  z-index: 65;
  max-width: 48.5rem;

  @screen lg {
    top: var(--header-lg);
  }

  &-close {
    z-index: 65;
  }

  &-header {
    @apply text-white pt-20 pl-12 pr-20 mb-12 pb-0;
  }

  &-content {
    @apply px-8 lg:px-12 mt-12;
  }
}

.MapOverlay-background {
  @apply inset-0 fixed bg-black opacity-40 z-30;
}

.TryOutTabs {
  & > div.splide__arrows:first-child {
    @apply text-white bg-grape-corn-dark;

    & > .arrow-bg--next {
      position: absolute;
      right: -64px;
      z-index: 10;
      width: 6.875rem;
      height: 4rem;
      background:
        linear-gradient(
          270.32deg,
          rgb(0 0 0 / 15%) 39.52%,
          rgb(0 0 0 / 0%) 81.86%
        );

      & > .splide__arrow {
        @each $colorType,
          $color
          in (
            corn-silage,
            grape-corn,
            sunflower,
            colz,
            forages,
            cereals,
            protein-crops,
            brand,
            gray
          ),
          (
            var(--corn-silage-dark),
            var(--grape-corn-dark),
            var(--sunflower-dark),
            var(--colz-dark),
            var(--forages-dark),
            var(--cereals-dark),
            var(--protein-crops-dark),
            var(--brand-600),
            var(--gray-900)
          ) {
          &.$(colorType) {
            background: $(color);
          }
        }
      }
    }

    & > .arrow-bg--prev {
      position: absolute;
      left: -40px;
      z-index: 10;
      width: 6.875rem;
      height: 4rem;
      background:
        linear-gradient(
          -270.32deg,
          rgb(0 0 0 / 15%) 39.52%,
          rgb(0 0 0 / 0%) 81.86%
        );
      transform: none;

      & > .splide__arrow {
        @each $colorType,
          $color
          in (
            corn-silage,
            grape-corn,
            sunflower,
            colz,
            forages,
            cereals,
            protein-crops,
            brand,
            gray
          ),
          (
            var(--corn-silage-dark),
            var(--grape-corn-dark),
            var(--sunflower-dark),
            var(--colz-dark),
            var(--forages-dark),
            var(--cereals-dark),
            var(--protein-crops-dark),
            var(--brand-600),
            var(--gray-900)
          ) {
          &.$(colorType) {
            background: $(color);
          }
        }

        &--prev svg {
          transform: none;
        }
      }
    }
  }
}

.page-content .tryout-results-map {
}

.Tryout {
  @apply px-4 pt-12 pb-24;

  @screen md {
    @apply px-4 py-16;
  }

  @screen lg {
    @apply px-12;

    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }

  @screen 3xl {
    @apply px-20;
  }

  & > .tryout-results-map {
  }
}
