.HeaderProfil {
  @apply bg-gray-900 text-white px-4 py-10 md:px-6 md:py-8 lg:p-12 xl:px-20 xl:py-16 w-full;

  &-container {
    @apply flex flex-col md:flex-row justify-between;
  }

  &-title {
    @apply mt-10 md:mt-0;

    max-width: 21.4375rem;

    @screen md {
      max-width: 35.8125rem;
    }

    @screen lg {
      margin-top: 3.625rem;
    }
  }

  &-nav {
    @apply hidden md:block;

    > ul {
      @apply flex items-end justify-end h-full;
    }
  }

  &-navBtn {
    @apply p-3 hover:text-white hover:bg-gray-800;

    transition: 0.2s ease-out;
  }
}
