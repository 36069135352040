.AgriculturalArea {
  @apply mt-10;

  &-title {
    @apply mb-5 text-base font-semibold;
  }

  &-area {
    @apply relative mb-6 flex ml-8 md:ml-0;
  }

  &-btnDelete {
    @apply absolute top-0 right-full p-3;

    > svg {
      @apply text-gray-900 hover:text-gray-600;

      width: 24px;
      height: 24px;
    }
  }

  &-fields {
    @apply border-l-4 border-brand-600 flex-grow pl-4 flex flex-col gap-y-4;
  }
}
