.Category {
  &-listing {
    @apply pb-12;
  }

  &-listingProducts {
    @apply border-t border-gray-200 flex flex-wrap;
  }

  &-listingProductsItem {
    @apply border-l border-gray-200 mt-8;
    @apply w-1/2;

    @screen lg {
      @apply w-1/3;

      &:nth-child(5) {
        @apply border-l-0;
      }
    }

    @screen xl {
      @apply w-1/4;
    }

    &:first-child,
    &:nth-child(3) {
      @apply border-l-0;
    }

    &:first-child {
      @screen lg {
        @apply border-l-0;
      }
    }

    &:nth-child(3) {
      @screen lg {
        @apply border-l border-gray-200;
      }
    }
  }
}
