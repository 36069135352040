/* --------- General style  --------- */

.Select,
.Textarea,
.TextField,
.PickupDate,
[data-editorblocktype='Field-dropdown'] select,
[data-editorblocktype='Field-text'] input,
[data-editorblocktype='Field-email'] input,
[data-editorblocktype='Field-phone'] input {
  @apply text-gray-600 border-gray-200 text-xs;

  width: 21.25rem;
  padding: 1.125rem 1rem 0.5rem;
  border-radius: 0.25rem;

  &:focus,
  &.open {
    @apply outline-none border-gray-600;

    box-shadow: none;
  }

  &::placeholder {
    opacity: 0;
  }
}

/* --------- Floating label  --------- */

.floating-label-wrapper {
  @apply relative flex;
}

.floating-label-wrapper.active {
  & > .floating-label {
    @apply text-gray-500;

    top: 0.4375rem;
    left: 1rem;
    font-size: 0.625rem;
    line-height: 0.760625rem;
    opacity: 1;
  }
}

.floating-label-wrapper.is-active {
  width: 95%;

  @screen lg {
    width: 100%;
  }
}

.floating-label {
  @apply text-gray-600 border-gray-200 text-xs;

  position: absolute;
  top: 0.875rem;
  left: 1rem;
  transition: 0.2s ease all;
  pointer-events: none;
}

.Select,
.Textarea,
.TextField,
.PickupDate {
  &:focus ~ .floating-label {
    @apply text-gray-500;

    top: 0.4375rem;
    left: 1rem;
    font-size: 0.625rem;
    line-height: 0.760625rem;
    opacity: 1;
  }

  &.error {
    @apply bg-brand-100 border-brand-600 text-brand-600;
  }

  &.success {
    @apply  border-corn-silage-dark;

    & ~ .TextField-valid {
      @apply block;
    }
  }
}

.Textarea:not(:placeholder-shown) ~ .floating-label,
.TextField:not(:placeholder-shown) ~ .floating-label,
.PickupDate:not(:placeholder-shown) ~ .floating-label,
.Select.selectedValue ~ .floating-label {
  @apply text-gray-500;

  top: 0.4375rem;
  left: 1rem;
  font-size: 0.625rem;
  line-height: 0.760625rem;
  opacity: 1;
}

/* dynamics 365 forms */
.marketing-field {
  [data-editorblocktype='Field-checkboxList'] .lp-checkboxListItem {
    @apply mb-3;
  }

  label {
    @apply mb-3 inline-block;
  }

  input {
    @apply block w-full;
  }
}

.Select + .floating-label,
.TextField + .floating-label {
  max-width: calc(100% - 2rem);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


.TextField-switchPassword {
  position: absolute;
  top: 50%;
  right: 1rem;
  color: theme('colors.gray.800');
  transform: translate(0, -50%);
  cursor: pointer;


  &:focus-visible {
    outline: 1px solid black;
  }

  &.is-noVisible {
    &::before {
      content: '';
    }
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 120%;
    height: 1px;
    background-color: black;
    border-radius: 4px;
    transform: translate(-50%, -50%) rotate(35deg);
    transform-origin: center;
  }
}

.TextField-valid {
  @apply absolute w-3.5 h-3.5 text-corn-silage-dark right-0 top-1/2 transform translate-x-6 -translate-y-1/2 hidden;
}

.TextField-errorMessage {
  @apply text-brand-600 pt-2 pb-4 text-xxs;
}

.TextField-notice {
  @apply pt-2  text-xxs flex items-start gap-1.5 text-gray-600;

}
