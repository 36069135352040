.SummaryCard {
  @apply flex flex-col bg-white overflow-hidden w-full cursor-pointer;

  max-width: 17.5rem;
  height: 100%;
  transition: all 0.3s ease-out;

  @screen lg {
    max-height: 150px;
  }

  &:hover {
    @apply text-gray-900;

    &:not(.open) {
      .SummaryCard-image,
      .SummaryCard-image--little {
        transform: scale(1.1) translateX(-0.8rem);
      }
    }
  }

  @screen lg {
    @apply flex-row;

    max-width: 100%;
    height: auto;
    min-height: 9.375rem;
    max-height: 9.375rem;
  }

  &-imageContainer {
    @apply overflow-hidden;

    max-width: 17.5rem;
    height: 13.125rem;

    @screen lg {
      max-width: 12.5rem;
      height: auto;
    }
  }

  &-image {
    @apply w-full h-full object-cover;

    transition: transform 0.8s ease;

    @screen lg {
      width: auto;
      max-width: none;
      height: auto;
      object-fit: none;
      object-fit: none;
      object-position: left;
      object-position: left;
    }

    &.placeholder {
      max-width: 280px;
      object-fit: contain;
      object-fit: contain;

      @screen lg {
        max-width: 200px;
      }

      &--vertical {
        max-width: 280px;
        object-fit: cover;
        object-fit: cover;

        @screen lg {
          max-width: 200px;
        }
      }
    }
  }

  &-image--little {
    @apply w-full h-full object-cover;

    transition: transform 0.8s ease;

    &.placeholder {
      max-width: 280px;
      object-fit: contain;
      object-fit: contain;

      @screen lg {
        max-width: 200px;
      }

      &--vertical {
        max-width: 280px;
        object-fit: cover;
        object-fit: cover;

        @screen lg {
          max-width: 200px;
        }
      }
    }
  }

  &-content {
    @apply flex flex-col flex-1 overflow-hidden;

    align-content: flex-start;
    min-height: 198px;
    padding: 24px 16px 32px;
    transition: max-height 0.8s ease;

    @screen lg {
      @apply justify-between items-start flex-row flex-wrap;

      min-height: auto;
      padding: 34px 3rem;
    }

    &.has-more {
      gap: 32px;
      padding: 32px 16px 16px;

      .SummaryCardChapo {
        /* stylelint-disable-next-line value-no-vendor-prefix */
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;

        @screen lg {
          -webkit-line-clamp: 2;
        }
      }

      @screen lg {
        gap: 30px;
        padding: 34px 48px 40px;
      }
    }

    &-header {
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: flex-start;
      justify-content: center;

      @screen lg {
        gap: 8px;
      }

      & > .SummaryCardChapo {
        @apply text-xs text-gray-600 lg:text-sm;

        /* stylelint-disable-next-line value-no-vendor-prefix */
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;

        @screen lg {
          -webkit-line-clamp: 2;
        }

        & > .tb-multiColumns {
          & > [data-tb-contain="blockImage"],
          & > [data-tb-contain*="blockTitle"] > .tb-blockText,
          & > [data-tb-contain*="blockText"] > .tb-blockText {
            display: none;
          }

          & > [data-tb-contain*="blockTitle"][data-tb-contain*="blockText"] {
            margin: 0;
          }

          & > .tb-blockTitle {
            @apply text-base;
          }
        }
      }

      @screen lg {
        @apply justify-start pointer-events-none;

        max-width: 80%;
      }
    }

    &-more {
      @apply w-full overflow-hidden hidden lg:flex flex-wrap opacity-0;

      transform: translateX(-100%);
      transition: opacity 0.8s ease;

      @screen lg {
        column-gap: 48px;
      }

      .labelInfo {
        @apply font-semibold text-xxs text-gray-900 mb-2 block;

        &:hover {
          @apply text-gray-600;
        }
      }

      .content {
        @apply flex flex-col;
      }

      .info {
        @apply text-gray-600 mr-auto;

        &:hover {
          @apply text-gray-900;
        }
      }
    }

    &-iconContainer {
      display: flex;
      align-items: center;

      @screen lg {
        height: 3.875rem;
      }
    }

    &-icon {
      @apply w-8 h-8 fill-current text-brand-600 justify-self-end ml-auto mt-auto;

      shape-rendering: crispedges;

      @screen lg {
        @apply ml-0 mt-0;
      }
    }
  }

  &.noContent.open {
    @screen lg {
      max-height: 9.375rem;
    }
  }

  &.noContent {
    & > .SummaryCard-image {
      @apply object-cover;

      width: 100%;
    }
  }

  &.open {
    @screen lg {
      @apply w-full;

      max-height: 27.5rem;

      .SummaryCard-content {
        height: fit-content;
        max-height: 27.5rem;
      }

      .SummaryCard-content-icon {
        transform: rotate(45deg);
      }

      .SummaryCard-imageContainer {
        /*
         * When open don't consider the image size for the parent height,
         * because we want the parent to fit the min-content
         */
        position: relative;
        min-width: 12.5rem;
        max-height: 100%;

        & > .SummaryCard-image {
          position: absolute;
        }
      }

      .SummaryCard-content-more {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }
}

.OverlaySummary {
  @apply fixed left-0 z-50 flex flex-col w-full bg-white overscroll-contain overflow-hidden;

  top: var(--header-base);
  height: calc(100vh - var(--header-base));
  transform: translateX(-100vw);
  transition: transform 300ms ease-out;

  @screen md {
    max-width: 430px;
  }

  @screen lg {
    top: var(--header-lg);
    height: calc(100vh - var(--header-lg));
  }

  &.open {
    transform: translateX(0);
  }

  &-header {
    @apply px-4 py-4.5 w-full flex items-center justify-end border-b border-gray-50 sticky;
  }
}

.SummaryContentOverlay {
  @apply flex flex-col overflow-y-scroll;

  &-title {
    @apply p-4 text-xs font-semibold border-t border-gray-50;

    &--special {
      @apply p-3.5 px-8 text-xs border-t border-gray-50;
    }
  }
}
