.CriteriasPanel {
  &-form {
    & div:first-child {
      border-top: none;
    }
  }

  &-input {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-top: 1px solid #f7f7f7;
  }
}
