.Subscriptions {
  @apply -mx-4 md:-mx-8 relative;

  &-alert {
    @apply text-center w-full;

    position: absolute;
    top: 0;
    transform: translateY(-80px);

    /* animate */
    animation: display_for_five_seconds 5s forwards;
  }

  &-list {
    @apply px-4 py-10 md:px-6 md:py-8 lg:p-12 xl:px-20 xl:py-16;
  }

  &-listTitle {
    @apply font-semibold text-xxs mb-4;
  }

  &-filter {
    @apply mb-6;
  }
}

@keyframes display_for_five_seconds {
  0% {
    transform: translateY(0);
  }

  90% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-80px);
  }
}
