.ProductAccessories {
  @apply -mx-4;

  @screen lg {
    display: flex;
    margin-left: -3rem;
  }

  &.no-js {
    @apply visible;

    .splide__list {
      @apply flex;
    }
  }

  & > .splide__track > .splide__list > .splide__slide {
    @screen md {
      width: 21.3125rem;
    }

    @screen lg {
      width: 21.3125rem;
    }

    @screen xl {
      width: 20rem;
    }

    @screen 2xl {
      width: 30rem;
    }
  }

  & > .splide__pagination {
    bottom: 0;

    & > li > .splide__pagination__page {
      @apply w-1.5 h-1.5;

      &.is-active {
        @apply bg-brand-600;

        transform: none;
      }
    }
  }
}
