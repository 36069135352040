.ServiceBloc {
  @apply text-gray-900 bg-white md:flex md:flex-wrap overflow-hidden;

  @screen md {
    min-height: 19.125rem;
  }

  @screen lg {
    min-height: 27.5rem;
  }

  @screen 3xl {
    min-height: 30rem;
  }

  &--dark {
    @apply bg-gray-900 text-white;

    .ServiceBloc-chapo,
    .ServiceBloc-chapo > p,
    .tb-blockText > p {
      @apply text-gray-500;
    }
  }

  &--reverse {
    @apply md:flex-row-reverse;
  }

  &-content,
  [data-tb-contain*='blockTitle'][data-tb-contain*='blockText'] {
    @apply flex flex-col items-start md:justify-center px-4 pt-10 pb-14 md:p-8 lg:p-16 xl:p-20 md:items-start md:w-1/2 m-0;
  }

  &-title,
  .tb-blockTitle {
    @apply w-full mb-4 lg:mb-6 text-mobile-xl lg:text-4xl;

    max-width: 40rem;
  }

  &-chapo,
  .tb-blockText > p,
  & > p {
    @apply text-sm text-gray-500 lg:text-base mb-6 lg:mb-10;

    max-width: 40rem;
  }

  &-logo {
    @apply mb-8;
  }

  &-wrapImage,
  .tb-multiColumns > [data-tb-contain='blockImage'] {
    @apply md:w-1/2 md:relative;
  }

  &-image,
  .tb-blockImage {
    @apply object-cover md:absolute w-full md:h-full;

    & > img {
      @apply block object-cover w-full h-full;
    }
  }
}
