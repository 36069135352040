.Table {

  @apply w-full mb-6 mt-6 bg-gray-400 border border-gray-100 border-opacity-25;

  th, td {
    text-align: left;
    @apply border-b border-gray-100 border-opacity-25 p-4;
  }
  thead  th {
    @apply text-xl bg-gray-500;
  }
  tbody tr:nth-child(odd) > td, tbody tr:nth-child(odd) > th {
    @apply bg-gray-300;
  }
}

.Table--sm {
  th, td {
    @apply p-2;
  }
  thead  th {
    @apply text-base;
  }

}
