.ServiceLanding {
  @apply py-12 md:pt-25 px-4 mx-auto max-w-7xl wysiwyg flex flex-col-reverse sm:flex-row items-stretch justify-center;

  &-content {
    @apply flex flex-col items-start sm:pr-16 lg:pr-24 pt-10 sm:pt-0;
  }

  &-title {
    @apply w-full mb-4 sm:mb-6 text-mobile-xl sm:text-4xl;

    max-width: 40rem;
  }

  &-chapo {
    @apply text-sm text-gray-600 sm:text-base mb-6 sm:mb-10 lg:max-w-sm;
  }

  &-wrapImage {
    @apply w-full;

    height: 383px;

    @screen sm {
      flex: 1 0 50%;
    }

    @screen lg {
      flex: 0 0 auto;
      width: 475px;
      height: 520px;
    }
  }

  &-image {
    @apply w-full h-full object-cover;
  }
}
