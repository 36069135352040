.Content {
  @apply -mx-4 md:-mx-8 overflow-x-hidden bg-white;

  &-articleContainer {
    @apply bg-white px-4 py-8;

    @screen lg {
      @apply flex;

      padding: 6.25rem 3rem;
    }
  }

  &-innovationContainer {
    @apply bg-white;

    & > .CycleCardContainer,
    & > .innovationArticle {
      @apply xl:mx-auto;

      @screen xl {
        max-width: 1280px;
      }
    }

    & > .CycleCardContainer {
      @apply flex flex-col gap-4;

      padding: 6.25rem 1rem;

      @screen lg {
        @apply flex-row flex-wrap mx-auto;

        max-width: 950px;
      }

      @screen xl {
        @apply px-12 gap-8;

        max-width: 1280px;
        padding-top: 100px;
        padding-bottom: 100px;
      }
    }
  }

  &-innovationArticle {
    @apply flex-grow;
  }

  &-article {
    @apply flex-grow mx-auto max-w-6xl;

    &.hasSidebar {
      max-width: 680px;
    }

    &.innovationArticle {
      @apply m-0 max-w-none;
    }
  }

  &-articleAnchors {
    @apply sticky hidden lg:w-60 lg:block;

    top: 128px;
    width: 300px;
    min-width: 300px;
    height: fit-content;
  }

  &-mainArticle {
    & > .tb-blockRaw > table {
      @apply w-full;
    }

    & > .tb-blockImage {
      margin-top: 40px;
      margin-bottom: 48px;
    }

    &.discover-format {
      & > .tb-blockList,
      & > .tb-blockButtonWrapper,
      & > .tb-blockTitle {
        @apply mx-auto;

        max-width: 680px;
      }
    }

    &:not(.discover-format).default-format {
      @apply mx-auto;

      max-width: 680px;
    }

    &.discover-format,
    &.innovation-format {
      h2 {
        @apply text-mobile-xl lg:text-4xl font-normal;
      }

      .tb-blockButtonWrapper {
        @apply my-6 text-left;
      }

      .tb-blockGroup,
      .tb-multiColumns {
        @apply mx-auto;

        max-width: 1280px;
      }

      .tb-multiColumns {
        [data-tb-contain*='blockImage']:first-child .tb-blockImage {
          margin-right: auto;
          margin-left: initial;
        }

        [data-tb-contain*='blockImage']:last-child .tb-blockImage {
          margin-right: initial;
          margin-left: auto;
        }
      }
    }

    &.innovation-format {
      h2 {
        @apply mb-6 text-mobile-xl xl:text-4xl;
      }

      h3 {
        @apply text-base leading-6 xl:text-2xl mb-6;
      }

      .tb-multiColumns {
        @apply px-4 py-8;

        @screen lg {
          @apply px-12 py-10
        }

        @screen xl {
          @apply px-16 py-16
        }

        [data-tb-contain*='blockTitle'][data-tb-contain*='blockText'] {
          @screen xl {
            @apply ml-16;
          }
        }

        .tb-blockImage {
          @screen xl {
            margin-bottom: 0;
          }
        }
      }

      .tb-blockGroup {
        @apply px-4 pb-12 pt-8;

        @screen lg {
          max-width: 680px;
          padding: 0 0 3rem;
        }

        & > .tb-blockImage {
          width: 100%;
        }
      }
    }

    .tb-blockFullWidthImage > img {
      height: 100vw;
      object-fit: cover;
      object-position: center;

      @screen lg {
        height: auto;
      }
    }
  }

  &-articleChapo {
    @apply pb-10 border-b border-gray-50 mb-10 text-base lg:text-xl;
  }

  &-folders {
    @apply px-4 py-12 bg-gray-50;

    @screen xl {
      @apply px-12;

      padding-top: 6.25rem;
      padding-bottom: 6.25rem;
    }

    @screen 3xl {
      @apply px-20;
    }
  }
}

.wysiwyg > .tb-blockTestimonial ul li::before {
  display: none;
}

.wysiwyg > .tb-blockTestimonial ul {
  display: flex;
  list-style: none;
}

.wysiwyg > .tb-blockTestimonial > .splide__pagination {
  bottom: -25px;

  & > li + li {
    @apply ml-2;
  }

  & > li > .splide__pagination__page {
    @apply w-1.5 h-1.5;

    &.is-active {
      @apply bg-brand-600;

      transform: none;
    }
  }
}
