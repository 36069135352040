.CheckboxService {
  @apply cursor-pointer relative block pb-12 lg:pb-0 bg-white;

  input {
    @apply w-0 h-0 border-0 absolute pointer-events-none;

    &:focus {
      background: none;
      outline: none;
      box-shadow: none;
    }

    &:checked ~ .CheckboxService-marker--toggler {
      &:nth-child(3) {
        @apply hidden;
      }

      &:last-child {
        @apply flex;
      }
    }
  }

  &-marker {
    @apply absolute bottom-0 lg:bottom-auto lg:top-1/2 right-8 transform -translate-y-1/2;

    &--toggler {
      &:last-child {
        @apply hidden;
      }
    }
  }

  .AppLauncher {
    max-width: 100%;

     @screen lg {
      padding-right: 160px;
     }

     &-contentChapo {
      @screen lg {
        max-width: 100%;
       }

       a {
        @apply underline font-semibold
       }
     }


  }
}
