.ButtonsFilter {
  @apply sticky flex justify-center md:justify-between w-auto z-10 pb-6 pt-12 flex-col md:flex-row items-center;

  top: -1px;

  @screen md {
    @apply bg-gray-50 px-12 py-5;
  }

  @screen xl {
    @apply mt-20;
  }

  @screen 3xl {
    @apply px-20;
  }

  &.active {
    @apply pt-5;

    & > .ButtonsFilter-desktop > * {
      @apply text-white;
    }
  }

  &-mobile {
    @apply flex items-center justify-center text-white bg-gray-900 divide-x lg:hidden text-xxs py-3 px-6;

    border-radius: 61px;
  }

  &-desktop {
    @apply flex w-full text-xxs;
  }
}

.ComparatorOverlay {
  @apply inset-0 fixed bg-white z-50 px-12 py-8;

  top: var(--header-base);
  transition: transform 450ms ease-out;
  transform: translateY(100vh);
  height: 100vh;
  width: 100%;
  z-index: 50;

  @screen lg {
    top: var(--header-lg);
  }

  &.open {
    transform: translateY(calc(-1 * var(--header-base)));

    @screen lg {
      transform: translateY(calc(-1 * var(--header-lg)));
    }
  }

  &-closeIcon {
    @apply fixed top-4.5 right-4 lg:top-8 lg:right-8;
  }

  &-content {
    @apply flex overflow-y-auto my-auto;

    @screen lg {
      max-height: 100%;
    }
  }
}

.ComparatorButton {
  @apply hidden ml-auto left md:flex;

  &-icon {
    @apply w-5 h-5 mr-2 fill-current;
  }
}
