:root {
  --bottom-position: 24px;
  --pagination-height: 17px;
}

.ProductSlide {
  margin-left: -1rem;

  @screen lg {
    display: flex;
    margin-left: -3rem;
  }

  & > .splide__track > ul {
    & > li {
      @screen md {
        width: 21.3125rem;
      }

      @screen lg {
        width: 20rem;
      }

      @media (min-width: 1440px) {
        width: 360px;
      }
    }
  }

  & > .splide__pagination {
    bottom: 0;

    @screen lg {
      bottom: -0.5rem;
    }

    & > li > .splide__pagination__page {
      @apply w-1.5 h-1.5;

      &.is-active {
        @apply bg-brand-600;

        transform: none;
      }
    }
  }
}
