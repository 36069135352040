.LifeCycleCard {
  @apply flex flex-col py-6 px-4 lg:px-6 bg-gray-50;

  width: 17rem;
  height: 100%;
  min-height: 20.0625rem;

  &-icon {
    @apply flex items-center justify-center mb-6 text-white rounded-full w-14 h-14 bg-brand-600;

    min-height: 3.5rem;
    min-width: 3.5rem;
  }

  &-title {
    @apply mb-4 font-semibold;
  }

  &-info {
    @apply text-xs text-gray-600 -mx-2;

    &-icon {
      @apply w-7 h-7 inline -mt-1 fill-current;

      min-width: 1.75rem;
    }

    &-item {
      max-width: 12.6875rem;
    }
  }
}
