.Candidature {
  @apply flex;

  width: 100%;
  height: calc(100vh - var(--header-lg));
  max-height: calc(100vh - var(--header-lg));
  overflow: hidden;

  &-infos {
    @apply flex justify-center p-12;

    flex-grow: 1;
    max-height: 100%;
  }

  &-form {
    @apply flex justify-center p-12 bg-white overflow-y-auto;

    flex-grow: 1;
    height: 100%;
  }
}
