.Product {
  @apply -mx-4 md:-mx-8;

  &-details,
  &-cycleOfPlant,
  &-maps,
  &-accessories,
  &-articles {
    @apply px-4 pt-12 pb-24;

    @screen md {
      @apply px-4 py-16;
    }

    @screen lg {
      padding: 6.25rem 3rem !important;
    }

    @screen 3xl {
      padding-right: 5rem !important;
      padding-left: 5rem !important;
    }

    & > h2 {
      @apply mb-4 text-mobile-xl md:text-4xl;
    }
  }

  &-buttons {
    @apply flex flex-col p-4 md:p-0 md:flex-row z-10;

    @screen md {
      @apply sticky;

      top: -1px;
      min-height: 60px;
      max-height: 60px;
    }

    @screen lg {
      min-height: 80px;
      max-height: 80px;
    }

    & > *:first-child {
      @apply mb-4 md:mb-0;
    }

    &.isStuck {
      @apply pl-5 lg:pl-12 z-50;

      & > h2 {
        @apply lg:flex items-center mr-auto text-white mb-0 text-base lg:text-lg font-semibold;
      }

      & > .Product-buttonsItemDesktop {
        @apply p-0 lg:flex items-center;

        @screen md {
          @apply text-xs;

          min-width: 258px;
          max-width: 258px;
        }

        @screen lg {
          @apply text-sm;

          min-width: 288px;
          max-width: 288px;
        }

        &:first-child {
          @apply ml-auto;
        }
      }
    }
  }

  &-buttonsItem,
  &-buttonsItemDesktop {
    & > svg {
      @apply w-6 h-6 fill-current mr-2;
    }
  }

  &-buttonsItem {
    @apply flex items-center justify-center flex-grow text-white md:hidden;
  }

  &-buttonsItemDesktop {
    @apply hidden md:flex bg-brand-600 flex-1;

    min-width: auto;
    max-width: inherit;

    @screen md {
      @apply text-xs p-0 flex justify-center items-center;

      line-height: 1.125rem;
    }

    @screen lg {
      @apply text-lg;
    }
  }

  &-details {
    @apply grid;

    @screen md {
      @apply px-6 py-16;

      grid-template-columns: 208px auto;
    }

    @screen lg {
      grid-template-columns: 1fr 3fr;
    }
  }

  &-detailsHead {
    & > h2 {
      @apply mb-4 text-mobile-xl md:text-mobile-xl lg:text-4xl;
    }

    & > p {
      @apply text-sm text-gray-600 md:text-base;
    }

    @screen md {
      @apply mb-8;

      order: 2;
    }
  }

  &-detailsHead,
  &-detailsFeatures {
    @screen md {
      grid-column-start: 2;
      min-width: 468px;
      max-width: 468px;
      margin-left: 2.125rem;
    }

    @screen lg {
      @apply ml-12 mr-0;

      min-width: 560px;
      max-width: 560px;
    }

    @screen xl {
      min-width: 680px;
      max-width: 680px;
      margin-left: 101px;
    }

    @screen 2xl {
      margin-left: 116px;
    }

    @screen 3xl {
      margin-left: 179px;
    }
  }

  &-detailsStrength {
    @screen md {
      & > div > p {
        @apply mb-2 text-xs text-gray-600;
      }
    }
  }

  &-detailsDescription {
    @screen md {
      @apply py-4;
    }
  }

  &-detailsFeatures {
    .Product-detailsTitle {
      @apply mb-4 md:mb-2 w-full;
    }

    @screen md {
      order: 3;
    }
  }

  &-detailsFeaturesItem {
    @apply gap-2 md:gap-4 flex flex-wrap;

    @screen md {
      & > button {
        @apply text-xs whitespace-nowrap;
      }
    }
  }

  &-detailsFeaturesItemOverlay {
    @apply fixed bg-white flex flex-col;

    top: 0;
    left: 0;
    z-index: 60;
    max-width: 430px;
    height: 100vh;
    transform: translateX(-430px);
    transition: 250ms ease-out;
    transition-property: transform, top;
    overflow: auto;

    @screen lg {
      top: var(--header-lg);
      height: calc(100vh - var(--header-lg));
    }

    &.open {
      transform: translateX(0);
    }
  }

  &-detailsFeaturesItemOverlayImageWrapper {
    @apply flex items-center justify-center bg-white;

    padding: 80px 60px;

    & > img {
      max-height: 373px;
    }
  }

  &-left {
    @screen md {
      @apply border-r border-gray-200 pr-4 order-1 row-span-2;
    }
  }

  &-detailsFeaturesItem + &-detailsFeaturesItem {
    @apply mt-8;
  }

  &-detailsTitle {
    @apply mb-6 text-xs font-semibold leading-4;
  }

  &-detailsStrength,
  &-detailsDescription,
  &-detailsFeaturesItem {
    @apply mt-10 md:mt-0;

    & > p {
      @apply mb-2 text-xs text-gray-600;

      line-height: 18px;

      @screen md {
        line-height: 20px;
      }

      & > span:first-child {
        @apply font-semibold;
      }
    }

    & > button + button {
      @apply mt-3;
    }
  }

  &-cycleOfPlant {
    @apply bg-white px-0;

    padding-right: 0!important;
    padding-left: 0!important;

    & > h2 {
      @apply mb-7 pl-4 lg:pl-0;

      @screen md {
        @apply mr-auto mb-8;

        max-width: 42.5rem;
      }

      @screen lg {
        @apply mx-auto mb-8;

        max-width: 42.5rem;
      }
    }

    @screen lg {
      padding-top: 100px;
      padding-bottom: 100px;
    }
  }

  &-profitability {
    @apply px-4 pt-12 pb-14;

    @screen md {
      @apply py-16 px-21;

      & > h2 {
        @apply mb-8;
      }
    }

    @screen lg {
      @apply mx-auto;

      max-width: 42.5rem;
      padding-top: 100px;
      padding-right: 0;
      padding-bottom: 163px;
      padding-left: 0;

      & > h2 {
        @apply mb-12;
      }
    }

    & > h2 {
      @apply mb-4 text-mobile-xl md:text-4xl;
    }
  }

  &-profitabilitySubTitle {
    @apply font-semibold text-mobile-2xl text-brand-600;
  }

  &-profitabilityDescription {
    @apply mt-4 text-sm text-gray-600;
  }

  &-profitabilityImage {
    @apply w-full mt-8;
  }

  &-advice {
    @apply bg-gray-900;

    @screen md {
      @apply flex flex-row-reverse;

      & > h2 {
        @apply mb-6;
      }
    }
  }

  &-adviceContent {
    @apply px-4 pt-12 pb-14;

    @screen md {
      @apply flex-1 py-16;
    }

    @screen lg {
      padding: 136px 5rem 3rem;
    }

    & > h2 {
      @apply mb-4 text-mobile-xl text-white;

      @screen md {
        @apply mb-6 text-4xl;
      }
    }

    & > p {
      @apply text-sm text-gray-500;

      @screen md {
        @apply text-base;
      }
    }
  }

  &-adviceImage {
    @apply flex-1 object-cover;

    min-width: 50%;
  }

  &-maps {
    @apply bg-white pb-14;

    @screen md {
      & > h2 {
        @apply mb-7;
      }

      & > .Rebound {
        & > .Rebound-content > iframe {
          @screen md {
            height: 34rem;
          }

          @screen lg {
            height: 43rem;
          }
        }
      }
    }
  }

  &-accessories {
    & > h2 {
      @apply text-center mb-8 md:mb-4 md:text-left;
    }
  }
}

.OverlayFindVariety {
  @apply fixed top-0 right-0 bg-white;

  top: var(--header-base);
  z-index: 50;
  width: 100%;
  height: calc(100vh - var(--header-base));
  transition: transform 350ms ease-in-out;
  transform: translateX(100%);

  @screen md {
    max-width: 30rem;
  }

  @screen lg {
    top: var(--header-lg);
    height: calc(100vh - var(--header-lg));
    transform: translateX(30rem);
  }

  &.open {
    box-shadow: 0 99em 99em 99em rgb(0 0 0 / 25%);
    transform: translateX(0);

    @screen lg {
      transform: translateX(0);
    }
  }

  &-header {
    @apply w-full md:px-12 pb-8 pt-12 px-4 text-white;

    height: 10.625rem;
  }

  &-closeIcon {
    @apply fixed top-4.5 right-4 lg:top-8 lg:right-8;
  }

  &-items {
    @apply flex overflow-y-auto my-auto px-2 py-8 w-full;

    height: calc(100% - 10.625rem);

    @screen md {
      @apply px-12 py-8;
    }

    @screen lg {
      max-height: 100%;
    }
  }

  &-itemsWrapper {
    @apply w-full  border-gray-200 divide-y;
  }

  &-item {
    @apply flex flex-col px-4 py-4 md:px-0;
  }

  &-itemTitle {
    @apply font-bold text-sm py-2;
  }

  &-itemLabel {
    @apply mb-2 text-xs font-semibold border-gray-200 whitespace-nowrap;
  }

  &-itemInfo {
    @apply text-xs text-gray-600;
  }
}
