.Select {
  --select-height: 48px;

  &,
  &--searchable,
  &--multiple {
    @apply bg-white border p-0;

    /* From tailwind forms config */
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20"><path stroke="%23A39F9F" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M6 8l4 4 4-4"/></svg>');
    background-repeat: no-repeat;
    background-position: right 0.5rem center;
    background-size: 1.5em 1.5em;

    &.ss-main {
      .ss-single-selected {
        height: var(--select-height);
      }

      .ss-multi-selected {
        min-height: var(--select-height);
      }

      .ss-single-selected,
      .ss-multi-selected {
        padding: 1.25rem 1rem 0.5rem;
        background: none;
        border: none;

        .ss-arrow,
        .ss-add {
          display: none;
        }
      }

      .ss-content,
      .ss-values {
        @apply border-gray-200;

        top: 100%;
        left: 0;

        .ss-search input {
          @apply border-gray-200;
        }

        .ss-list .ss-option {
          transition: 0.2s ease-out;

          &:hover {
            @apply bg-accent-200 text-gray-700;
          }

          &:not(.ss-disabled).ss-option-selected {
            @apply bg-accent-100;
          }
        }

        .ss-value {
          @apply border border-brand-600 bg-white text-brand-600;
        }
      }
    }
  }

  &.ss-invalid {
    border-color: theme('colors.brand.600');
  }

  &.ss-invalid ~ .floating-label {
    color: theme('colors.brand.600');
  }

  &.ss-invalid-message {
    display: none;
    color: theme('colors.brand.600');
  }

  &.ss-invalid .ss-invalid-message {
    display: block;
  }


  &--required:not(.ss-main) {
    @apply opacity-0 w-0 h-0 border-none;

    display: block !important;
  }
}

