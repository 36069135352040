.AgriTestingMarker {
  @apply flex items-center text-gray-500 font-semibold gap-1.5 text-xs;

  &::before {
    @apply w-2 h-2 block rounded-full bg-gray-400;

    content: '';

  }

  &--ongoing {
    @apply text-corn-silage;

    &::before {
      @apply bg-corn-silage;
    }
  }
}
