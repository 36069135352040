.panel {
  @apply bg-white p-4 mb-6 border border-transparent;

  @screen xl {
    @apply p-6;
  }

  &.shadow {
    @apply border-0;
  }
}

.panel--highlight {
  @apply border-brand-600;
}

.tb-blockHighlight a {
  text-decoration: none !important;
}

.tb-blockButtonCTA {
  text-decoration: none !important;
  @apply block mb-12;
}

.tb-blockButtonCTA-label {
  @apply font-semibold;

  display: inline-flex;
  align-items: center;

  &::after {
    display: inline-block;
    width: 1px;
    height: 1em;
    content: '';
    @apply bg-accent-400 mx-2;
  }
}


.tb-blockLandingSeo {
  display: flex;
  flex-direction: column-reverse;
  gap: 40px;
  align-items: center;
  padding: 60px 16px 30px;

  @screen lg {
    flex-direction: row;
    gap: 70px;
    padding: 120px 0 60px;
  }

  &Image {
    width: 100%;

    @screen lg {
      width: 49%;
    }
  }

  &Content {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @screen lg {
      gap: 20px;

    }
  }

  &Title {
    color: theme('colors.gray.900');
    font-size: 1.75rem;
    font-family: theme('fontFamily.brand');
    line-height: 1.75rem;

    @screen lg {
      max-width: 465px;
      font-size: 2.75rem;
      line-height: 2.75rem;
    }
  }

  &Description {
    color: theme('colors.gray.600');
    font-size: theme('fontSize.base');
    line-height: 1.625rem;

    @screen lg {
      max-width: 443px;
    }
  }

  &Button {
    margin-top: 0.5rem;

    @screen lg {
      margin-top: 0.75rem;
    }
  }

}

.tb-blockLandingKeyFigures {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  padding: 30px 55px;
  color: theme('colors.brand.600');

  @screen lg {
    flex-direction: row;
    gap: 43px;
    padding: 60px 55px;
  }

  &Key,
  &Label {
    text-align: center;
  }

  &Key {
    display: flex;
    align-items: flex-end;
    font-size: 6.25rem;
    font-family: theme('fontFamily.brand');
    line-height: 5.1875rem;
  }

  &KeyUnits {
    font-size: 4.375rem;
    font-family: theme('fontFamily.brand');
    line-height: 1;
  }

  &Label {
    font-size: theme('fontSize.2xl');
    line-height: 1.875rem;

    @screen lg {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }
}

.tb-blockLandingHero {
  position: relative;

  &-backdrop,
  &-img,
  &-title {
    position: absolute;
  }

  &-backdrop {
    z-index: 2;
    background: linear-gradient(0deg, rgb(0 0 0 / 40%) 0%, rgb(0 0 0 / 0%) 100%);

    @screen lg {
      background: linear-gradient(90deg, rgb(0 0 0 / 40%) 0%, rgb(0 0 0 / 0%) 100%);
    }
  }

  &-backdrop,
  &-img {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &-img {
    z-index: 1;
  }

  &-title {
    bottom: 40px;
    left: 16px;
    z-index: 3;
    max-width: 297px;
    color: theme('colors.white');
    font-weight: 400;
    font-size: theme('fontSize.6xl');
    font-family: theme('fontFamily.brand');
    line-height: theme('fontSize.6xl');

    @screen lg {
      bottom: 172px;
      left: 70px;
      max-width: 535px;
      font-size: theme('fontSize.8xl');
      line-height: theme('fontSize.8xl');
    }

  }
}


.tb-blockLandingProducts {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 16px 60px;

  @screen lg {
    gap: 40px;
    padding: 60px 70px 120px;
  }

  &Title {
    font-size: 28px;
    font-family: theme('fontFamily.brand');
    line-height: 28px;

    @screen lg {
      font-size: 44px;
      line-height: 44px;
      text-align: center;
    }
  }

  &ProductList {
    display: grid;
    grid-template-columns: repeat(2, minmax(164px, 164px));
    justify-content: center;
    column-gap: 16px;
    row-gap: 30px;

    @screen lg {
      grid-template-columns: repeat(4, minmax(228px, 228px));
      column-gap: 24px;
      row-gap: 38px;
    }

    @screen xl {
      grid-template-columns: repeat(4, minmax(267px, 267px));
    }
  }

  &Product {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
    padding: 0;

    &:hover {
      color: theme('colors.gray.900');
    }

    &:hover &Image img {
      transform: scale(1.075);
    }
  }

  &ProductTitle {
    font-size: 0.875rem;
    line-height: 1.25rem;

    @screen lg {
      font-size: 1.25rem;
      line-height: 1.625rem;
    }
  }

  &ProductImage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 184px;
    max-height: 184px;
    overflow: hidden;
    background-color: #f7f7f7;

    @screen lg {
      height: 256px;
      max-height: 256px;
    }

    @screen xl {
      height: 300px;
      max-height: 300px;
    }
  }

  &ProductImage img {
    margin: 0 auto;
    transition: transform 0.3s ease-out;
  }

  &Button {
    margin: 30px auto 0;
  }
}

.tb-blockLandingForm {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 60px 16px;
  background-color: theme('backgroundColor.gray.50');

  @screen lg {
    gap: 40px;
    padding: 120px 165px;
  }

  &Title {
    font-size: 28px;
    font-family: theme('fontFamily.brand');
    line-height: 28px;
    text-align: left;

    @screen lg {
      max-width: 747px;
      margin: 0 auto;
      font-size: 44px;
      line-height: 44px;
      text-align: center;
    }
  }

  &Success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    &Title {
      @apply text-6xl;
    }

    &Description {
      @apply text-base;

      max-width: 465px;
      margin-top: theme('spacing.6');
      font-weight: 400;
      text-align: center;
    }
  }
}

.Landing--form .tb-blockLandingForm + .tb-blockLandingSeo {
  padding-bottom: 60px;

  @screen lg {
    margin-top: 63px;
    padding-bottom: 120px;
  }
}
