.AgritestingListingActive {
  @apply divide-y;
  &-item {
    @apply grid py-6 gap-4;
    grid-template-columns: 170px 1fr;
    grid-template-areas:
      'img infos'
      'date date'
      'enquetes enquetes';

    @screen xl {
      @apply gap-x-4 gap-y-0;
      grid-template-columns: 170px auto 1fr;
      grid-template-rows: minmax(min-content, 128px) auto;

      grid-template-areas:
        'img infos enquetes'
        'date date enquetes';
    }

    &-img {
      grid-area: img;
    }
    &-infos {
      grid-area: infos;
    }
    &-enquetes {
      grid-area: enquetes;
      max-width: 780px;
      margin-left: auto;
      width: 100%;
      > div {
        display: flex;
      }
    }

    &-date {
      grid-area: date;
    }
  }
}
