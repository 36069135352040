.MulticriteriaTable {
  &-content {
    @apply animate-fade-in-down;

    padding: 48px 16px;

    &Infos {
      max-width: 574px;
      margin: 0 auto;
    }

    h2 {
      @apply text-base font-semibold mb-6;
    }

    th + th {
      @apply border-l;

      border-color: rgb(0 0 0 / 9%);
    }

    tr:first-of-type > th {
      @apply bg-gray-200 py-4.5 text-xs font-semibold text-center text-gray-900;
    }

    tr:first-of-type > th:first-of-type {
      min-width: 164px;
      max-width: 274px;
    }

    td {
      @apply text-xxs text-left;

      padding: 8px 16px;
    }

    tr > td:first-of-type {
      @apply font-semibold;
    }

    .OADManagment-table tr > td {
      @apply text-center lg:text-left;
    }

    .OADManagment-table tr > td:first-of-type {
      @apply text-left;
    }
  }
}
