.SummaryListTrack {
  @screen lg {
    @apply max-w-full w-full;
  }

  & > .splide__list {
    @screen lg {
      @apply w-full;
    }
  }

  &-list {
    @apply flex;

    @screen lg {
      @apply flex-col max-w-full w-full;
    }

    & > .splide__slide {
      @apply mb-4;
    }
  }

  &-listItem {
    width: 280px;

    @screen lg {
      @apply max-w-full mb-4 w-full;
    }
  }
}
