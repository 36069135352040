.NumberedList {
  @apply max-w-3xl mx-auto;

  & &-item {
    @apply flex items-center mb-4 bg-gray-100 p-4 lg:p-6;

    border-radius: 4px;

    &:before {
      @apply hidden;
    }
  }

  &-itemNumber {
    @apply flex justify-center items-center bg-brand-600 text-white text-sm font-semibold mr-6;

    border-radius: 50%;
    min-width: 2.5rem; /* 40px */
    min-height: 2.5rem; /* 40px */
  }

  &-itemText {
    @apply text-sm lg:text-lg font-semibold flex-grow text-gray-900;
  }
}
