html {
  scroll-padding-top: 80px;
}

body {
  @apply font-headline;

  .ql-align-center {
    text-align: center;
  }

  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.hasOverlayOpen {
    @apply overflow-hidden;

    @screen lg {
      padding-right: 15px; /* Avoid width reflow */
    }
  }

  &::after {
    @apply inset-0 pointer-events-none;

    position: fixed;
    z-index: 45;
    background: #000;
    opacity: 0;
    transition: opacity 300ms ease-in-out;
    content: "";
  }

  &.is-open {
    &::after {
      opacity: 0.5;
    }
  }
}

a {
  &:hover {
    @apply text-gray-400;
  }
}

.is-loading {
  pointer-events: none;
  opacity: 0.5;
  transition: all ease-in 0.15s;
}
