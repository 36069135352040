.DownloadContentList {
  @apply lg:-mx-4;

  &-track {
  }

  &-list {
    @apply flex divide-x;

    & > .splide__slide {
      @apply pr-4;

      ~ .splide__slide {
        @apply pl-4;
      }
    }
  }

  &-listItem {
  }
}
