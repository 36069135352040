.Error404 {
  @apply -mx-4 md:-mx-8;

  min-height: calc(100vh - var(--header-base));
  max-height: calc(100vh - var(--header-base));

  @screen lg {
    min-height: calc(100vh - var(--header-lg));
    max-height: calc(100vh - var(--header-lg));
  }

  &-container {
    @apply flex flex-col-reverse w-full md:flex-row;
  }

  &-imageWrapper {
    @apply flex-grow h-full w-full md:w-1/2;

    height: 375px;

    @screen lg {
      height: calc(100vh - var(--header-lg));
    }
  }

  &-content {
    @apply flex flex-col items-center justify-center px-4 py-12 md:px-20 xl:px-32 md:py-0 md:w-1/2;
  }

  &-title {
    @apply mb-8 text-brand-600 md:mx-auto;

    @screen xl {
      max-width: 480px;
    }
  }

  &-listContainer {
    @apply flex flex-wrap gap-x-20 md:mx-auto;

    @screen xl {
      max-width: 480px;
    }
  }

  &-listContainerItem {
    @apply w-48 mb-5 text-sm text-gray-600;
  }

  &-item {
    @apply pb-1 text-gray-600 border-b border-gray-600 hover:text-gray-600 hover:border-transparent;
  }
}
