.HeaderPage {
  @apply flex flex-col md:flex-row justify-between bg-white;

  &-content {
    @apply md:flex md:flex-col md:justify-between px-4 py-10 md:px-6 md:py-8 lg:p-12 3xl:px-20 3xl:py-16;

    &.takeAllSpace {
      @apply w-full;
    }

    &-features {
      @apply flex text-white mb-4 lg:mb-8;

      &.haveBreadcrumbs {
        @apply mt-10 lg:mt-24 xl:mt-20;

        @screen md {
          margin-top: 110px;
        }

        @screen lg {
          margin-top: 80px;
        }
      }

      &-item {
        @apply text-white flex flex-col;

        &-title {
          @apply font-semibold text-xxs lg:text-xs xl:text-sm mb-1;
        }

        &-value {
          @apply text-xxs lg:text-xs xl:text-sm;
        }
      }

      &-item + &-item {
        @apply ml-4 pl-4 lg:ml-8 lg:pl-8 xl:ml-10 xl:pl-10;

        border-left: 1px solid rgb(255 255 255 / 20%);
      }
    }

    &-title,
    &-chapo {
      max-width: 21.4375rem;

      @screen md {
        max-width: 35.8125rem;
      }

      @screen lg {
        max-width: 38rem;
      }
    }

    &-subTitle {
      @apply text-white text-xxs font-semibold mt-10 mb-2;

      @screen md {
        margin-top: 7.25rem;
      }

      @screen lg {
        margin-top: 2rem;
      }

      @screen xl {
        margin-top: 6.25rem;
      }
    }

    &-title {
      @apply text-mobile-2xl xl:text-7xl;

      &.haveBreadcrumbs {
        @apply mt-10;

        @screen xl {
          margin-top: 3.625rem;
        }
      }

      &.fullTitle {
        max-width: 21.4375rem;

        @screen xl {
          max-width: 40.1875rem;
        }
      }

      &.is-subscription {
        max-width: 21.4375rem;

        @screen xl {
          max-width: 880px;
        }
      }

      em {
        @apply lowercase ml-0.5 text-90cent  not-italic font-bold font-headline;
      }

      sup {
        @apply font-headline -top-2.5;
      }
    }

    &-chapo {
      @apply mt-6;
      @apply text-sm lg:text-base block;
    }
  }

  &-bio {
    @apply ml-4 my-auto font-semibold bg-white flex items-center justify-center hover:text-white btn whitespace-nowrap;

    max-height: 24px;
    padding: 6px 10px;
    font-size: 10px;
    line-height: 12px;
    border-radius: 16.4375rem;

    @screen lg {
      max-height: 48px;
      padding: 10px 14px;
      font-size: 14px;
      line-height: 16px;
    }
  }

  &-contentMain {
    @apply flex flex-col flex-grow justify-end items-center;

    @screen lg {
      @apply items-start;
    }

    &--premiumService {
      @media (max-width: theme('screens.md')) {
        @apply text-center items-center;
      }
    }
  }

  & > .tabs-content {
    @apply w-full;
  }

  &-containerImage {
    display: block;
    justify-self: flex-end;
    width: 100%;

    @screen md {
      max-width: 400px;
    }

    @screen lg {
      max-width: 460px;
    }

    @screen 3xl {
      max-width: 600px;
    }
  }

  &-image {
    @apply w-full h-full object-cover object-center;
  }

  &-stickyNav {
    @apply sticky z-10;

    top: -1px;
    transition: max-height 300ms ease-out;

    &.active {
      & > .tabs-content {
        max-height: 48px;

        @screen lg {
          max-height: 81px;
        }

        & > .splide__arrows > .arrow-bg--prev,
        & > .splide__arrows > .arrow-bg--next {
          /* top: 11px; */
          height: 48px;

          @screen lg {
            height: 5.0625rem;
          }
        }
      }

      & > .tabs-content > .tabs-list > ul > .tabs-list-item > div:first-child {
        display: none;
      }

      & > .tabs-content > .tabs-list > ul > .tabs-list-item > .tab {
        @screen lg {
          padding-top: 29px;
          padding-bottom: 29px;
        }
      }
    }
  }
}
