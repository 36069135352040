.PickupDate {
  &::-webkit-calendar-picker-indicator {
    display: none;
    appearance: none;
  }

  &-icon {
    @apply w-6 h-6;

    top: 0.875rem;
    right: 1.1875rem;
  }

  & ~.floating-label {
    max-width: 70%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.above ~ .flatpickr-calendar {
    top: -0.625rem;
    margin: 0;
    transform: translateY(-100%);

    &.open {
      animation: fadeInUp 300ms cubic-bezier(0.23, 1, 0.32, 1);
    }
  }
}

@keyframes fadeInUp {
  from {
    transform: translateY(-90%);
    opacity: 0;
  }

  to {
    transform: translateY(-100%);
    opacity: 1;
  }
}

/* Override flatpickr */

/* ------------------------------------------------------------------------------- */

.flatpickr {
  color: theme('colors.gray.900') !important;
  font-size: theme('fontSize.xs') !important;
  line-height: theme('lineHeight.5') !important;

  &-calendar {
    width: 21.25rem !important;
    margin-top: 0.625rem !important;
    padding: 1.5rem !important;
    border: 1px solid theme('borderColor.gray.600') !important;
    border: 1px solid var(--gray-600) !important;
    transition: transform 300ms ease-out !important;

    &::before,
    &::after {
      display: none !important;
    }
  }

  &-months .flatpickr-prev-month svg,
  &-months .flatpickr-next-month svg {
    width: 1.5rem !important;
    height: 1.5rem !important;
  }

  &-months .flatpickr-prev-month,
  &-months .flatpickr-next-month {
    top: 1.875rem !important;
    width: 1.5rem !important;
    height: 1.5rem !important;
    padding: 0;
  }

  &-months .flatpickr-prev-month.flatpickr-prev-month {
    left: 2.6431rem !important;
  }

  &-months .flatpickr-next-month.flatpickr-next-month {
    right: 2.6431rem !important;
  }

  &-current-month {
    left: 0 !important;
    display: flex !important;
    justify-content: center !important;
    width: 100% !important;
    margin-bottom: 0.75rem !important;

    & > span.cur-month,
    & > .numInputWrapper > .cur-year,
    & > .numInputWrapper {
      color: theme('colors.gray.900');
      font-weight: theme('fontWeight.normal') !important;
      font-size: theme('fontSize.xs') !important;
      line-height: theme('lineHeight.5') !important;
      text-transform: capitalize !important;

      &:hover {
        background-color: theme('backgroundColor.white') !important;

        & ~ .arrowUp,
        ~.arrowDown {
          opacity: 0 !important;
        }
      }
    }

    & > .numInputWrapper > .cur-year {
      @apply w-auto pointer-events-none;

      &:hover {
        @apply bg-white;

        & span {
          opacity: 0 !important;
        }
      }
    }
  }

  &-rContainer {
    width: 100% !important;
  }

  &-weekdays {
    margin: 0.75rem 0 !important;

    & > .flatpickr-weekdaycontainer > span.flatpickr-weekday {
      font-weight: theme('fontWeight.normal') !important;
      font-size: theme('fontSize.xs') !important;
      line-height: theme('lineHeight.5') !important;
    }

    & > .flatpickr-weekdaycontainer {
      display: grid !important;
      grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
      width: 100% !important;
    }
  }

  &-weekday {
    max-width: 100% !important;
    text-align: center !important;
  }

  &-days {
    width: 100% !important;

    & > .dayContainer {
      display: grid !important;
      grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
      width: 100% !important;
      min-width: 100% !important;
      max-width: 100% !important;
      column-gap: 0.75rem !important;
    }
  }

  &-day {
    display: flex !important;
    flex-basis: inherit !important;
    align-items: center !important;
    justify-content: center !important;
    justify-self: center !important;
    width: 2rem !important;
    height: 2rem !important;
    font-size: theme('fontSize.xs') !important;
    line-height: theme('lineHeight.5') !important;
    border-radius: 3.375rem !important;

    &:hover,
    &.selected,
    &.today:hover {
      color: theme('colors.white') !important;
      background-color: theme('backgroundColor.brand.600') !important;
      border-color: theme('borderColor.brand.600') !important;
    }

    &.selected:hover {
      color: theme('colors.white') !important;
      background-color: theme('backgroundColor.brand.500') !important;
      border-color: theme('borderColor.brand.500') !important;
    }

    &.prevMonthDay,
    &.nextMonthDay {
      color: theme('colors.gray.200') !important;

      &:hover {
        background-color: theme('backgroundColor.transparent') !important;
        border-color: theme('borderColor.transparent') !important;
      }
    }

    &.today {
      border-color: theme('borderColor.transparent') !important;
    }
  }
}
