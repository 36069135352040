.CreateMultiCriteriaTable {
  &-modal {
    @apply bg-white;

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    transform: translateY(100%);
    transition: transform 300ms ease-out;

    &.fromLeft {
      transform: translateX(-100%);
    }

    &.open {
      transform: translateY(0);

      &.fromLeft {
        transform: translateX(0);
      }
    }

    @screen lg {
      right: unset;
      width: 100%;
      max-width: 430px;
      transform: translateX(-100%);

      &.open {
        transform: translateX(0);
      }
    }

    &Header {
      @apply mb-6;

      &Close {
        @apply flex items-center justify-end py-6 pr-5;
      }

      &Title {
        @apply px-4 lg:px-6;
      }

      &Prev {
        @apply font-semibold bg-gray-50 text-gray-900 -mx-4 px-4 py-3.5;
      }

      &PrevButton {
        @apply flex items-center gap-x-1;
      }
    }

    &Content {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;
      max-height: calc(100vh - 240px);
      padding: 0 16px;
      overflow-y: auto;
      row-gap: 32px;

      &::after {
        content: '';
      }

      @screen lg {
        padding: 0 24px;
      }

      &Group {
        @apply w-full;
      }

      &GroupButtons {
        @apply gap-y-3;

        display: flex;
        flex-direction: column;
      }
    }

    &ContentGroupButton {
      @apply text-gray-500 text-base text-left;

      display: flex;
      padding: 26px 16px;
      border-radius: 4px;
      outline: 1px solid #eeeded;
      outline-offset: -1px;

      &:hover {
        @apply bg-gray-50 text-gray-600;

        outline: 1px solid #eeeded;
      }

      &:focus {
        outline: 1px solid #eeeded;
        outline-offset: -1px;
      }

      @screen lg {
        padding: 26px 24px;
      }
    }

    &Footer {
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      margin: 0 16px;
      padding: 32px 0;
      background-color: #fff;
      border-top: 1px solid #eeeded;

      &::before {
        position: absolute;
        top: -50px;
        right: 0;
        left: 0;
        z-index: 1;
        display: block;
        width: 100%;
        height: 50px;
        background: linear-gradient(
          0deg,
          rgb(255 255 255 / 100%) 0%,
          rgb(255 255 255 / 50%) 87%,
          rgb(255 255 255 / 0%) 100%
        );
        content: '';
      }

      @screen lg {
        margin: 0 24px;
      }
    }
  }

  &-buttonLoading {
    @apply text-center;

    min-width: 207px;
    min-height: 50px;

    svg {
      @apply mx-auto;
    }
  }
}

.CreateMultiCriteriaTable-modal.fromLeft
  .CreateMultiCriteriaTable-modalContent {
  max-height: calc(100vh - 124px);
}
