.Register {
  @apply lg:flex;

  &-services {
    @apply px-4 py-10;

    @screen lg {
      @apply w-1/2 order-1 p-12;
    }
  }

  &-form {
    @apply bg-white py-10 px-4;

    box-shadow: 99em 0 0 99em #fff;

    @screen lg {
      @apply w-1/2 order-2 py-16 px-20;
    }

    .mb-4 {
      @apply mb-2;
    }
  }
}
