.SingleProduct {
  @apply bg-gray-50 w-full;
  @apply px-4 pb-5;

  @screen md {
    @apply p-6;
  }

  @screen md {
    @apply px-12 py-6;
  }

  &:hover {
    .CheckboxContainer {
      @apply visible;
    }

    @screen lg {
      @apply bg-white;
    }
  }

  @screen xl {
    @apply px-12 pb-6;

    padding-top: 1.625rem;
  }

  &-title {
    @apply mb-4 mt-1.5 text-base font-semibold text-gray-900 w-full;
  }

  &-text {
    @apply text-xs text-gray-600 mb-6 w-full flex-grow;

    & > * {
      @apply mb-1.5;
    }
  }

  &-image {
    @apply flex w-full h-full max-h-64;

    img {
      @apply object-contain h-full w-full max-h-64;
    }

    @screen lg {
      width: 100%;
      height: auto;
    }

    @screen 3xl {
      @apply mx-auto;

      width: 320px;
      height: auto;
    }
  }

  .CheckboxContainer {
    @apply flex;
  }
}
