.BestVarietiesProducts {
  display: grid;
  gap: 40px;
  margin: 0 -1rem;
  padding: 3rem 2rem;
  overflow: hidden;
  background-color: #f7f7f7;

  @screen sm {
    margin: 0 -2rem;
  }

  @screen lg {
    grid-template-columns: 1fr 3fr;
    padding: 6rem 4rem;
  }

  &-description {
    & h2 {
      margin-bottom: 20px;
    }

    & p {
      @apply text-xs;
    }


    @screen lg {
      min-width: 280px;
    }
    @screen xl {
      min-width: 350px;
    }
  }

  &-list {
    @apply flex flex-col sm:flex-row justify-end overflow-x-auto items-stretch;
  }
}
