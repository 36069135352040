.AppLauncher {
  @apply flex flex-col w-full bg-white lg:flex-row;

  max-width: 18rem;

  @screen lg {
    max-width: 100%;
  }

  a& {
    transition: 0.2s ease-out;
    transition-property: color, background-color;

    &:hover {
      @apply bg-gray-200 text-current;
    }
  }



  &--lite {
    .AppLauncher-content {
      padding: 24px 16px 32px;

      @screen lg {
        height: auto;
        padding: 34px 48px 34px 32px;
      }
    }

    .AppLauncher-image {
      @screen lg {
        max-width: 12.5rem;
      }
    }
  }

  &-image {
    @apply w-full max-w-full;

    @screen lg {
      max-width: 12.5rem;
    }
  }

  &-content {
    @apply px-4 py-6 lg:p-6 flex flex-col lg:flex-row h-full w-full items-center;

    &Wrapper {
      @apply flex flex-col gap-2;
    }

    &Chapo {
      @apply text-xs lg:text-sm block;

      max-width: 525px;
    }

    &Url {
      @apply underline text-gray-900 inline-block text-xs lg:text-sm;

      transition: 0.1s linear;
    }
  }

  &-buttonContainer {
    @apply mt-8 lg:mt-0 lg:pl-4 self-start lg:self-center lg:pb-0 lg:ml-auto lg:whitespace-nowrap flex flex-col-reverse lg:flex-col lg:items-end items-start gap-3 lg:gap-8;
  }

  &-test {
    @apply flex items-center text-gray-500 font-semibold gap-1.5 text-xs;

    &::before {
      @apply w-2 h-2 block rounded-full bg-gray-400;

      content: '';

    }

    &--ongoing {
      @apply text-corn-silage;

      &::before {
        @apply bg-corn-silage;
      }
    }
  }
}

