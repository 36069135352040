.ControllPassword {
  @apply text-xxs max-w-sm my-4 mt-2;

  &-notice {
    @apply block mb-2;
  }

  &-grid {
    @apply grid sm:grid-cols-2 gap-x-4 gap-y-1;
  }

  &-control {
    @apply flex items-center gap-1.5;

    &::before {
      @apply w-2 h-2 rounded-full bg-gray-500 flex-shrink-0;

      content: '';
    }

    &.is-valid {
      &::before {
        @apply bg-corn-silage;
      }
    }
  }
}
