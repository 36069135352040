.CultureAdvice {
  @apply -mx-4 md:-mx-8;

  &-folders,
  &-faq {
    @apply px-4 py-12;

    @screen xl {
      @apply px-12;

      padding-top: 6.25rem;
      padding-bottom: 6.25rem;
    }

    @screen 3xl {
      @apply px-20;
    }
  }

  &-foldersCardList {
    @apply md:flex md:flex-wrap;
  }

  &-sectionTitle {
    @apply mb-8 text-mobile-xl;
  }

  &-faq {
    @apply bg-white;
  }

  &-faqContent {
    max-width: 680px;

    @screen xl {
      @apply mx-auto;
    }
  }
}
