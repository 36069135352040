.Culture {
  @apply text-white bg-gray-900 pb-14 lg:pb-28 lg:px-12 lg:pt-28;

  & > h2 {
    @apply px-4 pt-12 pb-8 lg:p-0 text-mobile-xl lg:text-4xl lg:pb-10;
  }

  &-content {
    @apply flex flex-col lg:flex-row;

    &-nav {
      @apply flex flex-col border-t border-b border-gray-700 divide-y lg:divide-y-0 w-full whitespace-nowrap;

      @screen lg {
        @apply border-t-0 border-b-0 w-auto;
      }

      &-item  {
        @apply flex justify-between p-4 border-gray-700;

        @screen lg {
          @apply border-l-4 border-t-0 border-b-0 border-transparent;

          &:hover {
            @apply bg-gray-800 cursor-pointer;
          }
        }

        &.selected {
          @screen lg {
            @apply border-brand-600 bg-gray-800 border-t-0 border-b-0 cursor-default;
          }
        }
      }
    }

    &-cards {
      @apply flex-wrap overflow-hidden max-h-0 lg:py-0 lg:ml-20 flex;

      transition: max-height 300ms ease-out;

      @screen lg {
        @apply w-full;

        min-height: 532px;
        max-height: max-content;
      }

      @screen xl {
        min-height: 708px;
      }

      &.open {
        max-height: 100%;
      }

      &-container {
        @apply overflow-hidden hidden;

        &.open {
          @apply block;

          transform: translateY(0);
        }
      }

      &-item:first-child {
        @apply mt-4 lg:mt-0;
      }

      &-item:last-child {
        @apply mb-4 lg:mb-0;
      }

      &-item {
        @apply flex mb-3 lg:mb-0 text-gray-900 bg-gray-800 lg:bg-gray-50 lg:flex-col lg:flex-1 overflow-hidden cursor-pointer;

        width: 100%;
        height: 78px;

        @screen lg {
          transition: max-width 300ms ease-out;

          &:not(.onlyTwo) {
            max-width: 33.3333%;
          }

          &.onlyTwo {
            max-width: 33.3333%;
          }
        }

        &:hover {
          @screen lg {
            @apply bg-white;
          }
        }

        &.selected {
          @apply bg-white;

          max-width: 550px;

          @screen xl {
            max-width: 688px;
          }

          @screen 2xl {
            max-width: 828px;
          }

          & > .Culture-content-cards-item-container {
            width: 100%;

            & > .Culture-content-cards-item-content {
              max-width: 280px;

              @screen lg {
                max-width: 272px;
              }

              @media (min-width: 1440px) {
                max-width: 280px;
              }

              @screen 2xl {
                max-width: 340px;
              }
            }

            & > .Culture-content-cards-item-features {
              @apply lg:ml-10;

              transform: translateX(0);
              opacity: 1;
            }
          }
        }

        &.notSelected {
          max-width: calc((100% - 550px) / 2);

          @screen xl {
            max-width: calc((100% - 688px) / 2);
          }

          @screen 2xl {
            max-width: calc((100% - 828px) / 2);
          }

          @screen xl {
            min-width: 10rem;
          }

          @screen 3xl {
            min-width: 20rem;
          }
        }

        & > picture > img,
        & > img {
          /* max-width: 101px;
          max-height: 100%; */

          @screen lg {
            width: 100%;
            max-width: none;
            height: 300px;
            object-fit: cover;
            object-position: left;
          }
        }

        &-container {
          @apply px-4 py-5 lg:flex lg:overflow-hidden lg:p-8;

          width: 300%;
        }

        &-features {
          @apply hidden ml-20 lg:flex;

          transform: translateX(100%);
          opacity: 0;
          transition: 800ms ease-out;
          transition-property: opacity;

          @screen 2xl {
            @apply ml-40;

            margin-left: 10rem;
          }

          &-feature {
            @apply flex flex-col text-xxs font-semibold text-gray-600;

            .label {
              @apply text-gray-900;
            }

            > a {
              @apply my-2 -ml-2;

              &:hover {
                @apply text-gray-600;

                > span {
                  @apply bg-gray-300;
                }
              }

              > span {
                @apply px-2 py-1 rounded-2xl;
              }
            }

            &.hasSub + .hasSub {
              @apply mt-2;
            }
          }
        }

        &-content {
          @apply w-full pr-2;

          @screen md {
            max-width: calc(33% - 4rem);
          }

          & > .title {
            @apply mb-4 text-xs text-white whitespace-nowrap;

            @screen lg {
              @apply text-gray-900 text-2xl whitespace-normal;
            }
          }

          & > div {
            width: 280px;
            min-width: 280px;
            max-width: 100%;

            @screen lg {
              width: 100%;
              max-width: 206px;
            }

            @screen xl {
              max-width: 272px;
            }

            @media (min-width: 1440px) {
              max-width: 280px;
            }

            @screen 2xl {
              max-width: 340px;
            }
          }

          & > div > p {
            @apply hidden text-sm text-gray-600 lg:block;
          }
        }

        @screen lg {
          height: 100%;
        }
      }
    }
  }
}
