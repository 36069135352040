.LandingHeader {
  --width-cta: 205px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  background-color: theme('backgroundColor.white');

  @screen lg {
    justify-content: flex-end;
    padding: 12px 70px;
  }

  @screen 2xl {
    max-width: 1600px;
    margin: 0 auto;
  }

  &-logo {
    .icon {
      width: theme('spacing.10');
      height: theme('spacing.10');

      @screen lg {
        width: theme('spacing.14');
        height: theme('spacing.14');
      }
    }

    @screen lg {
      margin: 0 calc(50% - var(--width-cta) - 28px ) 0 0;
    }
  }

  &-cta {
    gap: 4px;
    max-width: var(--width-cta);
    padding: 4px 14px;
    font-weight: 600;
    font-size: 0.625rem;
    line-height: 1.375rem;

    @screen lg {
      padding: 8px 16px;
      font-size: theme('fontSize.xxs');
      line-height: theme('fontSize.sm');
    }
  }
}
