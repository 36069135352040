.ProfilForm {
  @apply md:px-10 lg:px-4;

  &-fieldset {
    @apply py-8 md:py-16 border-b;

    &.needFlex {
      @apply md:flex;
    }

    &.needFlex.hidden {
      display: none;
    }
  }
}
