.CycleCard {
  @apply mb-4 border border-gray-200 text-left flex flex-col;

  @screen lg {
    @apply w-1/3;

    max-width: 270px;
  }

  @screen xl {
    @apply w-1/4 mb-0;

    max-width: 270px;
  }

  &-imageContainer {
    @apply flex items-center justify-center w-full;

    padding: 58px 38px;
    height: 199px;
  }

  &-contentContainer {
    @apply px-6 py-4 bg-gray-50 w-full;

    flex-grow: 1;
  }
}

.CycleCardOverlay {
  @apply fixed bg-gray-50 flex flex-col z-50;

  transition: 250ms ease-out;
  transition-property: transform, top;
  transform: translateX(-100%);
  top: var(--header-base);
  left: 0;
  max-width: 430px;
  height: calc(100vh - var(--header-base));

  @screen lg {
    top: var(--header-lg);
    height: calc(100vh - var(--header-lg));
  }

  &.open {
    transform: translateX(0);
  }

  &-wrapImage {
    @apply flex items-center justify-center bg-white;

    padding: 80px 60px;
  }
}
