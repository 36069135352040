.OADManagmentWrapper {
  .HeaderPage-content-chapo {
    max-width: 100%;
  }
}

.OADManagment {
  @apply px-4 pt-12 lg:px-12 lg:py-16;

  & > div,
  form:not(.settings) > div {
    @screen lg {
      max-width: 574px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  &-title {
    @apply text-base font-semibold text-gray-900 mb-2;

    @screen lg {
      @apply text-2xl;
    }
  }

  &-description {
    @apply text-sm text-gray-600;
  }

  &-form {
    @apply flex flex-col gap-y-3 w-full;
  }

  &-actions {
    position: absolute;
    top: 40px;
    right: 16px;
    display: flex;
    column-gap: 12px;

    @screen lg {
      top: 64px;
      right: 80px;
    }

    @screen 3xl {
      top: 64px;
      right: 64px;
    }

    button {
      padding: 4px;
      color: #fff;
      background-color: rgb(0 0 0 / 20%);
      border-radius: 100%;
    }
  }

  &-actionsCard {
    @apply absolute hidden mx-auto lg:flex gap-x-2 top-14 right-72;

    button {
      padding: 8px;
      background-color: #EDEDED;
      border-radius: 100%;
    }
  }

  &-actions,
  &-actionsCard {
    overflow: hidden;
  }

  &-varietyCard {
    @apply bg-white animate-fade;

    display: flex;
    align-items: center;
    padding: 1rem 0.75rem;
    font-size: 0.75rem;
    line-height: 1rem;

    @screen lg {
      padding: 1rem 1.5rem;
    }

    &Label {
      min-width: 80px;
      font-weight: 600;
      font-size: 0.875rem/* 14px */;
      line-height: 1.25rem/* 20px */;

      @screen lg {
        @apply text-base;
      }
    }

    &Button {
      @apply text-white text-xxs bg-brand-600;

      display: flex;
      align-items: center;
      padding: 0.5rem 0.5rem 0.5rem 0.75rem;
      border-radius: 263px;

      @screen lg {
        @apply text-xs pl-4.5 pr-3.5;

        line-height: 1.125rem;
      }
    }
  }

  &-fillSowingDate {
    @apply flex items-center gap-x-2;

    &Button {
      @apply text-xs font-semibold;

      padding: 13px 24px;
      line-height: 1.125rem;
    }
  }

  .alert {
    @apply my-6;
  }

  .PickupDate {
    width: 100%;
  }

  &-table {
    width: 100%;
    margin-top: 24px;
    overflow: auto;

    @screen lg {
      max-width: 982px;
      margin-right: auto;
      margin-left: auto;
    }

    tr:nth-child(2) > th:first-of-type,
    tr:first-of-type > th:not(:first-of-type) {
      @apply bg-gray-200 py-4.5 text-xs font-semibold text-center text-gray-900;
    }

    tr:nth-child(2) > th:first-of-type {
      @apply p-3.5;
    }

    tr:nth-child(2) > th:not(:first-of-type) {
      @apply bg-white p-3.5 text-xs font-normal text-center text-gray-600 border border-gray-200;
    }

    tr > td {
      @apply bg-white text-center border border-gray-200;

      padding: 14px;

      &:not(:first-of-type) {
        @apply px-8;
      }
    }
  }

  .stickyCol {
    position: sticky !important;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.OADManagmentDashboard {
  @apply px-4 pt-0 lg:px-12 lg:pb-16 3xl:px-20;

  min-height: 675px;

  &-nav {
    display: block;
    padding: 0 16px;
    overflow-y: hidden;

    @screen lg {
      padding: 0 48px;
    }

    @screen 3xl {
      padding: 0 80px;
    }

    .tabs-list {
      top: 0;
      margin: 0;
      padding: 0;
      overflow-y: hidden;
    }

    .tabs-list-item {
      @apply flex-grow;
    }

    .tab {
      @apply px-3 py-4 text-xs lg:text-sm lg:py-5;

      min-width: 0;
      line-height: 18px;
    }
  }

  &-cycle {
    @apply my-12 animate-fade;

    &Header,
    &Footer {
      max-width: 576px;
      margin-right: auto;
      margin-left: auto;
    }

    &Footer {
      @apply mt-8 text-xs text-gray-600;
    }

    &Captions {
      @apply flex flex-col gap-y-1;

      margin-top: 1.5rem;
      margin-bottom: 1.5rem;

      @screen lg {
        flex-direction: row;
        column-gap: 1.5rem;
      }
    }
  }

  &-timeline {
    margin: 0 -16px;
    padding: 0 16px;
    overflow: hidden;

    @screen lg {
      margin: 0 -48px;
      padding: 0 48px;
    }

    @screen 3xl {
      margin: 0 -80px;
      padding: 0 80px;
    }
  }

  &-timelineLineWrapper {
    @apply flex flex-col gap-y-2 text-xs text-center text-gray-900;

    margin-bottom: 16px;

    @screen lg {
      margin-bottom: 22px;
    }

    &Main {
      @apply relative h-1 -ml-2 lg:-ml-3 bg-accent-300;

    }

    &Main.first {
      @apply ml-0;

      border-radius: 110px 0 0 110px;

      & > div:first-of-type {
        border-radius: 110px 0 0 110px;
      }
    }

    &Indicator {
      @apply absolute z-10 overflow-y-hidden overflow-x-hidden;

      top: 0;
      left: calc(50% - 6px);
      width: 28px;
      height: 12px;
    }

    &IndicatorTriangle {
      @apply absolute bg-brand-600;

      top: -8px;
      left: calc(50% - 8px);
      width: 16px;
      height: 16px;
      transform: rotate(45deg);
    }
  }

  &-timelineCard {
    @apply flex flex-col items-center w-full h-full pt-4 px-8 bg-white;

    @screen lg {
      padding-top: 42px;
    }

    &Label {
      @apply w-full text-sm font-semibold text-center lg:text-base lg:mb-1;
    }

    &Date {
      @apply leading-none text-center text-xxs text-gray-500 lg:text-xs;
    }

    &Date.active {
      @apply text-gray-900;
    }

    &Image {
      @apply mt-auto;
    }
  }

  &-harvest {
    @apply text-xs text-gray-600 animate-fade;

    max-width: 576px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 48px;
    padding-bottom: 100px;

    @screen lg {
      padding-top: 64px;
    }

    &Header {
      @apply flex flex-col gap-y-4 lg:gap-y-6 mb-14;
    }

    .TextField {
      width: 102px;

      @screen lg {
        width: 127px;
      }
    }

    &FormWrapper {
      @apply  flex flex-col max-w-full mx-0 lg:items-center gap-y-4 lg:flex-row lg:gap-x-3;

      @screen lg {
        max-width: 100% !important;
        margin: 0 !important;
      }
    }

    .OADManagment-fillSowingDate {
      @screen lg {
        max-width: 100%;
        margin: 0;
        padding: 0 10px;
      }
    }

    .Modal-overlay {
      align-items: flex-end;

      @screen lg {
        align-items: center;
      }
    }

    .Modal-container {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      max-width: 100%;
      max-height: 100%;
      padding-top: 80px;
      padding-right: 16px;
      padding-bottom: 48px;
      padding-left: 16px;
      text-align: center;

      @screen lg {
        width: 100%;
        max-width: 576px;
        padding: 64px 32px;
      }
    }

    .Modal-header {
      flex-direction: column;
    }

    .Modal-content {
      display: flex;
      flex-direction: column;
    }

    .Modal-title {
      @apply text-base lg:text-2xl;

      @screen lg {
        max-width: 316px;
      }
    }

    .Modal-title.full {
      @screen lg {
        max-width: 100%;
      }
    }

    .Modal-subTitle {
      @apply text-sm text-gray-600;
    }
  }

  &-harvestOptimum {
    @apply flex items-center gap-x-4;

    &Date {
      @apply px-6 py-4 bg-gray-100 border border-gray-300 text-xs font-semibold text-center lg:text-base;

      border-radius: 4px;
    }
  }

  &-pluviometry,
  &-temperature {
    @apply flex flex-col animate-fade;

    padding-top: 64px;
    padding-bottom: 64px;

    &Header,
    .OADManagment-description {
      @apply flex flex-col;

      width: 100%;
      max-width: 576px;
      margin-right: auto;
      margin-left: auto;
    }

    .tempMax {
      background-color: #FFA895;
    }

    .tempMini {
      background-color: #FADDBB;
    }
  }

  &-chartWrapper {
    width: 896px;

    @screen lg {
      width: auto;
      max-width: 896px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  .splide__track {
    @apply overflow-visible;
  }

  .PickupDate {
    width: 100%;
  }
}

.OADManagmentModal {
  &-overlay {
    @apply fixed inset-0 pointer-events-none;

    z-index: 60;
    max-width: 100vw;
    background: rgb(0 0 0 / 60%);
    opacity: 0;
    transition: opacity 350ms cubic-bezier(0, 0, 0.2, 1);
    will-change: transform;
  }

  &.open {
    .OADManagmentModal-overlay {
      @apply pointer-events-auto;

      opacity: 1;
    }

    .OADManagmentModal-container {
      transform: translate(0,0);
    }
  }

  &-container {
    @apply bg-white px-4 pt-14 pb-10 relative;

    max-width: 100vw;
    height: 100%;
    max-height: 100vh;
    overflow-y: auto;
    border-radius: 2px;
    transform: translateY(100%);
    transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
    will-change: transform;

    @screen lg {
      @apply px-12 py-16;

      max-width: 576px;
      transform: translateX(-576px);
    }
  }

  &-header {
    @apply flex flex-col gap-y-2 mb-6;
  }

  &-close {
    @apply absolute bg-transparent border-none p-2 top-4 right-4 outline-none;

    position: absolute;
    transition: 0.2s ease-out;

    > svg {
      pointer-events: none;
    }

    &:hover,
    &:focus {
      opacity: 0.5;
    }
  }

  &-content {
    @apply text-sm text-gray-600 flex flex-col;
  }

  &--lite {
    .OADManagmentModal {
      &-overlay {
        @apply flex items-end justify-end lg:justify-center lg:items-center;
      }

      &-header {
        @apply text-center;
      }

      &-container {
        @apply w-full h-auto px-4 py-12;

        transform: translateY(375px);

        @screen lg {
          width: auto;
          max-width: 576px;
          max-height: 336px;
          padding: 64px 52px;
          transform: translateY(576px);
        }
      }
    }

    &.open > .OADManagment-container {
      transform: translateY(0);
    }
  }

  &--large {
    .OADManagmentModal-container {
      @screen lg {
        width: auto;
        max-width: 776px;
        padding: 80px 48px;
        transform: translateX(-776px);
      }
    }
  }
}
