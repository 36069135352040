.SeoSection {
    @apply px-4 py-12;

    @screen md {
      @apply px-6 py-1;
    }

    @screen lg {
      padding: 100px 48px;
    }

    @screen xl {
      padding: 100px 150px;
    }



  .tb-blockSeparator + .tb-multiColumns {

  }



  [data-tb-contain^='blockTitle'] {
    @apply md:w-1/2;

    * {
      @apply mb-4 text-sm lg:text-base;
    }

    .tb-blockTitle {
      @apply mb-4 text-gray-900 text-mobile-xl lg:text-4xl font-normal;
    }
  }

  .tb-blockSeparator {
    @apply my-6 md:my-10;
  }

  &--faq &-content {
    @apply mx-auto;

    max-width: 680px;
  }
}
