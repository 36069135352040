.TopBar {

  @apply bg-gray-900 text-white flex justify-center py-1.5 sm:py-2.5 text-xxs sm:text-xs px-5  text-center leading-3 top-0 w-full absolute left-0;

  height: var(--topbar-height);

  a {
    @apply block sm:inline-block underline;
  }

  &-btn {
    @apply absolute right-3 top-1/2 transform -translate-y-1/2 p-2;
  }
}
