.ServiceTab {
  @apply relative flex-grow;

  input {
    @apply w-0 h-0 border-0 absolute pointer-events-none;

    &:focus {
      background: none;
      outline: none;
      box-shadow: none;
    }

    &:checked + .ServiceTab-label {
      @apply bg-brand-600 text-white border-brand-600;

      &::after {
        transform: translate(-50%, 8px) rotate(45deg);
      }
    }
  }

  &-label {
    @apply h-full flex relative font-brand bg-white cursor-pointer text-lg lg:text-3xl items-center text-center justify-center font-normal px-5 py-8 border border-gray-200   transition;

    &:hover {
      @apply bg-gray-50;
    }

    &::after {
      position: absolute;
      bottom: 0;
      left: 50%;
      z-index: -1;
      display: block;
      width: 30px;
      height: 30px;
      background: theme('colors.brand.600');
      transform: translate(-50%, -10px) rotate(45deg);
      transform-origin: center;
      transition: transform 0.1s;
      content: '';
    }
  }
}
