button {
  &:focus {
    @apply outline-none shadow-none;
  }
}

.btn {
  @apply font-headline font-semibold select-none cursor-pointer text-center;

  transition: 0.2s ease-out;
  transition-property: color background-color;

  &--rounded {
    @apply px-6 py-3.5 lg:px-8 lg:py-3 border-brand-600 bg-transparent text-brand-600 text-xs lg:text-sm;

    box-sizing: border-box;
    border: 2px solid;
    border-radius: 4.8125rem;

    &:hover {
      @apply bg-brand-600 text-white border-brand-600;
    }

    &:active {
      @apply bg-brand-500 text-white border-brand-500 outline-none shadow-none;
    }

    &:disabled {
      @apply bg-transparent text-gray-200 border-gray-200;
    }

    &-special {
      @apply px-8 py-3 border-white bg-transparent text-white text-sm;

      box-sizing: border-box;
      border: 2px solid;
      border-radius: 4.8125rem;

      &:hover {
        @apply bg-white text-brand-600 border-white;
      }

      &:active {
        @apply bg-gray-50 text-brand-600 border-gray-50 outline-none shadow-none;
      }

      &:disabled {
        @apply bg-transparent text-brand-300 border-brand-300;
      }
    }

    &-filled {
      @apply px-8 py-3 text-brand-600 bg-white text-sm;

      box-sizing: border-box;
      border: 2px solid #fff;
      border-radius: 4.8125rem;

      &:hover {
        @apply text-white bg-brand-600;
      }

      &:active {
        @apply bg-brand-400 text-white border-brand-400 outline-none shadow-none;
      }

      &:disabled {
        @apply text-gray-400 bg-gray-200 border-gray-200;
      }
    }
  }

  &--rectangle {
    @apply bg-brand-600 text-white flex justify-center;

    padding: 1.75rem 6.1875rem;

    & > .icon {
      @apply w-6 h-6 fill-current mr-3.5;
    }

    &:hover {
      @apply bg-brand-400;
    }

    &:active {
      @apply bg-brand-500;
    }

    &:disabled {
    }

    &-black {
      @apply bg-gray-900;

      &:hover {
        @apply bg-gray-700;
      }

      &:active {
        @apply bg-gray-800;
      }

      &:disabled {
      }
    }
  }

  &--secondary,
  &--secondaryRed,
  &--secondaryInverted {
    @apply text-xxs lg:text-xs font-semibold flex items-center py-2 px-3.5;

    border-radius: 16.4375rem;

    &.left {
      @apply py-2 pr-4 pl-3;
    }

    &.right {
      @apply py-2 pl-4 pr-3;
    }

    &:disabled {
      @apply text-gray-500 bg-gray-100;

      &:hover {
        @apply text-gray-500 bg-gray-100;
      }
    }

    & > .icon-left {
      margin-right: 0.60375rem;
    }

    & > .icon-right {
      margin-left: 0.60375rem;
    }
  }

  &--whatsapp {
    @apply text-xxs  font-semibold flex items-center py-1.5 px-3  mt-3 ;


    & > .icon-left {
      margin-right: 0.40375rem;
    }

    
  }

  &--secondary {
    @apply bg-gray-200 text-gray-700;

    &:hover {
      @apply bg-gray-300 text-gray-700;
    }

    &:active {
      @apply bg-gray-400 text-gray-700;
    }
  }

  &--secondaryAccent {
    @apply bg-accent-100 text-accent-400 hover:bg-accent-300 hover:text-accent-400;

    &:visited {
      @apply bg-gray-200 text-gray-900;

      &:hover {
        @apply bg-gray-300 text-gray-900;
      }

      &:active {
        @apply bg-gray-400 text-gray-900;
      }
    }
  }

  &--secondaryInverted {
    @apply bg-gray-750 border-gray-750 text-white;

    &:hover {
      @apply bg-gray-800 border-gray-800 text-white;
    }

    &:active {
      @apply bg-gray-900 border-gray-900 text-white;
    }
  }

  &--secondaryRed {
    @apply bg-brand-600 text-white p-1 lg:py-2 lg:pl-3.5 lg:pr-4;

    & > p {
      @apply hidden lg:inline-block;
    }
  }

  &-list {
    @apply text-gray-900 text-xxs;

    padding: 0.25rem 0.5rem 0.3125rem;
    border-radius: 2.5rem;

    &:hover {
      @apply bg-gray-300 text-gray-600;
    }
  }

  &-link {
    @apply text-gray-900 text-sm border-gray-900 font-normal border-b;

    &:hover {
      @apply border-b-0 text-gray-900;
    }

    &:visited {
      @apply border-gray-500 text-gray-500;

      &:hover {
        @apply border-gray-500 text-gray-500;
      }
    }
  }

  &--link {
    @apply text-gray-900 text-sm border-gray-900 font-normal border-b;

    &:hover {
      @apply border-transparent text-gray-900;
    }

    &:disabled {
      @apply border-gray-500 text-gray-500;

      &:hover {
        @apply border-gray-500 text-gray-500;
      }
    }
  }

  &--linkWhite {
    @apply text-white text-sm border-white font-normal border-b;

    &:hover {
      @apply border-transparent text-white;
    }
  }
}
