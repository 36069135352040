.tb-blockCTA {
  position: relative;
  max-width: initial;
  margin: 3rem 0;

  @screen lg {
    right: 50%;
    left: 50%;
    width: 100vw;
    min-height: 27.5rem;
    margin-right: -50vw;
    margin-left: -50vw;
    padding: 0 3rem;
  }
}

.Content-article.hasSidebar > .wysiwyg > .tb-blockCTA {
  position: relative;
  max-width: initial;

  @screen lg {
    right: 50%;
    left: 50%;
    width: 100vw;
    margin-right: calc(-50vw - 150px);
    margin-left: calc(-50vw - 150px);
    padding: 0 3rem;
  }
}

.ServiceModule,
.wysiwyg > .tb-blockCTA > .ServiceModule {
  @apply text-white bg-brand-600 md:flex;

  &--reverse {
    @apply md:flex-row-reverse;
  }

  picture {
    display: block;
    height: 280px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @screen md {
      height: auto;
    }
  }

  @screen md {
    min-height: 21.75rem;
    max-height: 21.75rem;
  }

  @screen lg {
    min-height: 27.5rem;
    max-height: 31.375rem;
  }

  @screen xl {
    max-height: 28.875rem;
  }

  @media (min-width: 1440px) {
    max-height: 30rem;
  }

  @screen 3xl {
    max-height: 32.875rem;
  }

  &-image {
    @apply object-cover w-full md:w-1/2;
  }

  &-content {
    @apply flex flex-col items-center justify-center px-4 py-10 text-center md:p-6 lg:p-12 xl:p-20 md:items-start md:text-left md:w-1/2;

    & > form:not(.subscriptionForm) {
      @apply mx-auto;

      width: 100%;
      max-width: 25rem;
    }

    & > #form-register {
      @apply flex-wrap gap-x-4 justify-between;

      & > div {
        @apply w-full;
      }
    }
  }

  &-contentLabel {
    @apply mb-4 lg:mb-8 text-xs font-semibold lg:text-sm;
  }

  &-contentChapo {
    @apply mt-4 text-xs lg:text-sm;

    max-width: 40rem;
  }

  h2,
  &-contentTitle {
    @apply text-white md:text-mobile-2xl lg:text-6xl;

    max-width: 40rem;
  }

  &-contentButton {
    @apply text-xs lg:text-sm lg:leading-6;

    &.webCallback {
      margin-top: 3.25rem;

      @screen lg {
        @apply mt-10;
      }
    }
  }
}

.OverlayCta {
  @apply fixed flex flex-col w-full bg-white overscroll-contain text-gray-900 max-w-screen;

  top: 0;
  right: 0;

  /* position */
  left: 0;
  z-index: 70;

  /* size */
  height: 100vh;
  transform: translateY(150vh);

  /* transition */
  transition: 300ms ease-in-out;
  transition-property: transform, top, height;

  @screen md {
    max-width: 100%;
  }

  &.open {
    /* backdrop */
    box-shadow: -20em -20em 0 99em rgb(0 0 0 / 25%);
    transform: translateY(0);
  }

  &-closeIcon {
    @apply fixed top-4.5 right-4 lg:top-8 lg:right-8;
  }

  &-content {
    @apply flex overflow-y-auto;

    @screen lg {
      height: 100%;
      max-height: 100%;
    }
  }

  &-formContainer {
    max-height: 100%;
    padding: 2.875rem 3rem;
    overflow-y: auto;

    @screen lg {
      @apply py-20 px-12;
    }
  }
}
