.BestVarietiesTable {
  margin: 0 -1rem;
  padding: 3rem 2em;
  background-color: #f7f7f7;

  @screen sm {
    margin: 0 -2rem;
  }

  @screen lg {
    padding: 0 4rem 6rem;
  }

  &-tableContainer {
    width: 100%;
    overflow-x: hidden;

    &Scrollable {
      overflow-x: auto;
    }
  }

  &-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &Group {
      display: flex;
      gap: 15px;
      width: 100%;
    }
  }

  & table {
    width: 100%;
    margin-top: 15px;
    border-collapse: collapse;
  }

  & th {
    @apply text-xxs;

    padding: 15px 10px;
    color: #fff;
    text-align: left;
    background: #000;
  }

  & td {
    @apply text-xxs;

    padding: 15px 10px;
    background-color: #fff;
    border: 1px solid #EEEDED;
  }

  & tr.recommended > td {
    background-color: #ECFFEA;
    border: 1px solid #fff;
  }
}
