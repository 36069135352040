.Rebound {
  @apply flex flex-col;

  &.no-js {
    .Rebound-content {
      @apply block lg:flex;
    }
  }

  &-nav {
    @apply flex text-base mb-6;

    &-item  {
      @apply ml-4 text-gray-500 btn border-b-4 border-transparent;

      font-weight: normal;
      padding: 0.4375rem;

      &:first-child {
        @apply ml-0;
      }

      &.selected {
        @apply border-brand-600 text-gray-900;
      }
    }
  }

  &-content {
    @apply hidden;

    &.selected {
      @apply md:flex block animate-fade-in-down;
    }
  }
}
