.RadioButton {
  @apply w-5 h-5;

  border-color: #E3E3E3;

  &:active, &:checked {
    color: var(--brand-600);
    &:focus {
      @apply outline-none shadow-none;
      box-shadow: none;
    }
  }
  &:focus {
    @apply outline-none shadow-none;
    box-shadow: none;
  }

  & + label {
    @apply cursor-pointer;
  }
}
