.OverlayFilters,
.OverlayDetails {
  @apply fixed left-0 z-50 flex flex-col w-full bg-white overscroll-contain;

  top: var(--header-base);
  z-index: 65;

  /* size */
  height: calc(100vh - var(--header-base));
  transform: translateX(-100%);

  /* transition */
  transition: 300ms ease-in-out;
  transition-property: transform, top, height;

  @screen md {
    max-width: 430px;
    transform: translateX(-430px);
  }

  @screen lg {
    height: calc(100vh - var(--header-lg));
  }

  &.open {
    transform: translateX(0);
  }

  &.without-header {
    height: 100vh;
  }
}

.OverlayFilters {
  top: 0;
  z-index: 60;
  height: 100vh;

  &-header {
    @apply px-4 py-4.5 w-full flex items-center justify-end border-b border-gray-50 sticky;
  }

  &-items {
    @apply overflow-auto;
  }

  &-itemsCategory {
    @apply border-t border-gray-50;

    &:first-child {
      @apply border-t-0 border-transparent;
    }

    &Checkbox {
      @apply border-t border-gray-50 text-xs;

      padding: 0.9375rem 1rem;
    }

    &TitleWrapper {
      @apply flex items-center justify-between;

      padding: 0.9375rem 1rem;
    }

    &Title {
      @apply text-xs font-semibold whitespace-nowrap;
    }

    &Details {
      @apply px-2 py-1 text-xxs whitespace-nowrap underline text-gray-600;
    }
  }

  &-footer {
    @apply flex px-4 py-2 mt-auto justify-around border-t border-gray-50 items-center;

    &.active {
      .OverlayFilters-footerReset {
        @apply text-gray-600 border-b border-gray-600;
      }

      .OverlayFilters-footerBtn {
        @apply border-brand-600 text-brand-600 hover:text-white;
      }
    }
  }

  &-footerReset {
    @apply text-xs text-gray-200;

    padding-bottom: 1px;
    line-height: 1.125rem;
  }

  &-footerBtn {
    @apply px-20 py-4 border-gray-200 text-gray-200 text-xs;

    line-height: 1.125rem;
  }
}

.OverlayDetails {
  &-header {
    @apply w-full flex items-center justify-start bg-transparent border-t border-b border-gray-50 sticky font-semibold;

    padding: 14px 32px;

    & > span {
      @apply text-xs font-semibold text-gray-900;
    }
  }

  &-content {
    @apply overflow-auto divide-y;
  }

  &-contentItem {
    @apply px-4 py-6;
  }

  &-contentItemImg {
    width: auto;
    max-height: 100px;
    margin-bottom: 1rem;
  }

  &-contentItemLabel {
    @apply text-xs font-semibold mb-2 border-gray-200 whitespace-nowrap;
  }

  &-contentItemChapo {
    @apply text-xs text-gray-600;
  }
}
