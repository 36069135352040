@font-face {
  font-weight: normal;
  font-family: 'Microbrew One';
  font-style: normal;
  src: url('../fonts/MicrobrewOne.woff2') format('woff2'),
    url('../fonts/MicrobrewOne.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-weight: normal;
  font-family: 'Source Sans Pro';
  font-style: normal;
  src: url('../fonts/SourceSansPro-Regular.woff2') format('woff2'),
    url('../fonts/SourceSansPro-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-weight: 600;
  font-family: 'Source Sans Pro';
  font-style: normal;
  src: url('../fonts/SourceSansPro-SemiBold.woff2') format('woff2'),
    url('../fonts/SourceSansPro-SemiBold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-weight: 400;
  font-family: Suti;
  font-style: normal;
  src: url('../fonts/Suti-Regular.woff2') format('woff2'),
    url('../fonts/Suti-Regular.woff') format('woff');
  font-display: swap;
}
