* {
  text-underline-offset: 0.3em;
}

.typo-brand {
  &-page-title {
    @apply text-gray-800 uppercase text-7xl font-brand;

    letter-spacing: -0.025rem;
  }

  &-cta {
    @apply text-6xl text-gray-800 uppercase font-brand;

    letter-spacing: -0.025rem;
  }

  &-main-menu {
    @apply text-3xl text-gray-800 uppercase font-brand;

    letter-spacing: -0.025rem;
  }
}
