.ResetPasswordContainer {
  @apply container mx-auto mt-0 lg:mt-24 overflow-y-auto;

  max-height: calc(100vh - var(--header-base));

  @screen lg {
    max-height: calc(100vh - var(--header-lg) - 6rem);
  }
}

.ResetPassword {
  @apply flex flex-col justify-center max-w-lg py-4 m-auto md:py-10;

  height: calc(100vh - var(--header-base));

  @screen md {
    height: auto;
  }

  &-title {
    @apply mb-8 text-2xl text-center text-gray-900;
  }

  &-form {
    @apply flex flex-col justify-center;
  }
}
